export const setupMenu = [
  {
    label: "PREFERENCES",
    link: "#",
    permission: "SUBSCRIBED",
    children: [
      {
        label: "Company Information",
        link: "/setup/preferences/company-information",
        permission: "SUBSCRIBED"
      },
      {
        label: "Tax Code",
        link: "/setup/preferences/tax-code",
        permission: "SUBSCRIBED"
      },
      {
        label: "Location",
        link: "/setup/preferences/location",
        permission: "SUBSCRIBED"
      },
      {
        label: "Project/Jobs",
        link: "/setup/preferences/project",
        permission: "SUBSCRIBED"
      },
      {
        label: "Bank",
        link: "/setup/preferences/bank",
        permission: "SUBSCRIBED"
      },
      {
        label: "Religion",
        link: "/setup/preferences/religion",
        permission: "SUBSCRIBED"
      },
      {
        label: "Tax Type",
        link: "/setup/preferences/tax-type",
        permission: "SUBSCRIBED"
      },
      {
        label: "Modul",
        link: "/setup/preferences/module",
        permission: "SUBSCRIBED"
      },
      {
        label: "Menu",
        link: "/setup/preferences/menu",
        permission: "SUBSCRIBED"
      },
      { 
        label: "Transaction",
        link: "/setup/preferences/transaction",
        permission: "SUBSCRIBED"
      },
      {
        label: "Feature",
        link: "/setup/preferences/feature",
        permission: "SUBSCRIBED"
      },
      {
        label: "Type Industry",
        link: "/setup/preferences/type-industry",
        permission: "SUBSCRIBED"
      },
      {
        label: "Setting Company",
        link: "/setup/preferences/setting-company",
        permission: "SUBSCRIBED"
      },

    ]
  },
  {
    label: "ACCOUNTS",
    link: "#",
    permission: "SUBSCRIBED",
    children: [
      {
        label: "Coa Group",
        link: "/setup/accounts/coa-group",
        permission: "SUBSCRIBED"
      },
      {
        label: "Coa Type",
        link: "/setup/accounts/coa-type",
        permission: "SUBSCRIBED"
      },
      {
        label: "Link Account General",
        link: "/setup/accounts/link-account",
        permission: "SUBSCRIBED"
      },
      {
        label: "Link Account Sales",
        link: "/setup/accounts/link-account-sale",
        permission: "SUBSCRIBED"
      },
      {
        label: "Link Account Purchase",
        link: "/setup/accounts/link-account-purchase",
        permission: "SUBSCRIBED"
      },
    ]
  },
  {
    label: "FINANCE",
    link: "#",
    permission: "SUBSCRIBED",
    children: [
      {
        label: "PERIODE",
        link: "/setup/finance/periode",
        permission: "SUBSCRIBED"
      }, 
      {
        label: "COMPARATION",
        link: "/setup/finance/comparation",
        permission: "SUBSCRIBED"
      }
    ]
  },
  {
    label: "SALES",
    link: "#",
    permission: "SUBSCRIBED",
    children: [
      {
        label: "Category Customer",
        link: "/setup/sales/category-customer",
        permission: "SUBSCRIBED"
      },
      {
        label: "Payment Term Customer",
        link: "/setup/sales/payment-term-customer",
        permission: "SUBSCRIBED"
      },
      {
        label: "Price Level Customer",
        link: "/setup/sales/price-level-customer",
        permission: "SUBSCRIBED"
      },
    ]
  },
  {
    label: "PURCHASES",
    link: "#",
    permission: "SUBSCRIBED",
    children: [
      {
        label: "Category Vendor",
        link: "/setup/purchases/category-vendor",
        permission: "SUBSCRIBED"
      },
      {
        label: "Payment Term Vendor",
        link: "/setup/purchases/payment-term-vendor",
        permission: "SUBSCRIBED"
      },
      {
        label: "Price Level Vendor",
        link: "/setup/purchases/price-level-vendor",
        permission: "SUBSCRIBED"
      },
    ]
  },
  {
    label: "INVENTORY",
    link: "#",
    permission: "SUBSCRIBED",
    children: [
      {
        label: "Product Unit",
        link: "/setup/inventory/product-unit",
        permission: "SUBSCRIBED"
      },
      {
        label: "Product Group",
        link: "/setup/inventory/product-group",
        permission: "SUBSCRIBED"
      },
      {
        label: "Product Category",
        link: "/setup/inventory/product-category",
        permission: "SUBSCRIBED"
      },
      {
        label: "Product Material",
        link: "/setup/inventory/product-material",
        permission: "SUBSCRIBED"
      },
      {
        label: "Product Colour",
        link: "/setup/inventory/product-colour",
        permission: "SUBSCRIBED"
      },
      {
        label: "Product Size",
        link: "/setup/inventory/product-size",
        permission: "SUBSCRIBED"
      },
      {
        label: "Product Style",
        link: "/setup/inventory/product-style",
        permission: "SUBSCRIBED"
      },
      {
        label: "Product Status",
        link: "/setup/inventory/product-status",
        permission: "SUBSCRIBED"
      },
      {
        label: "Product Pricing Level",
        link: "/setup/inventory/product-pricing-level",
        permission: "SUBSCRIBED"
      },
    ]
  },
  {
    label: "CARDS",
    link: "#",
    permission: "SUBSCRIBED",
    children: [
      {
        label: "Departement",
        link: "/setup/card/departement",
        permission: "SUBSCRIBED"
      },
      {
        label: "Divisi",
        link: "/setup/card/divisi",
        permission: "SUBSCRIBED"
      },
      {
        label: "S3",
        link: "/setup/card/s3",
        permission: "SUBSCRIBED"
      },
      {
        label: "S2",
        link: "/setup/card/s2",
        permission: "SUBSCRIBED"
      },
      {
        label: "Univercity",
        link: "/setup/card/univercity",
        permission: "SUBSCRIBED"
      },
      {
        label: "Academy",
        link: "/setup/card/academy",
        permission: "SUBSCRIBED"
      },
      {
        label: "SMU/SMK",
        link: "/setup/card/sma",
        permission: "SUBSCRIBED"
      },
      {
        label: "SMP",
        link: "/setup/card/smp",
        permission: "SUBSCRIBED"
      },
      {
        label: "SD",
        link: "/setup/card/sd",
        permission: "SUBSCRIBED"
      },
      {
        label: "Group Position",
        link: "/setup/card/group-position",
        permission: "SUBSCRIBED"
      },
      {
        label: "Salary Type",
        link: "/setup/card/salary-type",
        permission: "SUBSCRIBED"
      },
      {
        label: "Job Position",
        link: "/setup/card/job-position",
        permission: "SUBSCRIBED"
      },
      {
        label: "Employee Status",
        link: "/setup/card/employee-status",
        permission: "SUBSCRIBED"
      },
    ]
  }
]