// React
import { createContext } from "react"

// Types
import type { PoType } from "../types"

export const PoContext = createContext<PoType>({
  datapo: {
    address: "",
    location_id: "",
    trx_code: "",
    vendor_id: "",
    vendor_name: ""
  }
})