import { useToggle } from "../../../../../../../../../utils"
import { Fragment, useContext} from "react"
import { IoSendSharp } from "react-icons/io5"
import { Modal, Loading } from "../../../../../../../../../components"
import { LinkAccountSalesType } from "../../../../../types"
import { useAccountGroup } from "../../../../../../../../../utils"
import { SelectedCoaType } from "../../../../../../../../../types"
import { toast } from "react-hot-toast"
import { useApi } from "../../../../../../../../../utils"
import { Render } from "../../../../../utils" 
import { CoaList } from "../../../../../../../../../components/widgets/CoaList"

export function Create(props: {
    dataItem: LinkAccountSalesType
    coaType: string | undefined
}) {
    // hooks
    const { isActive, toggle } = useToggle(false)

    return (
        <Fragment>
            <button onClick={toggle}>
                <IoSendSharp className="text-center" />
            </button>
            {isActive && <ModalSection toggle={toggle} dataItem={props.dataItem} coaType={props.coaType}/>}
        </Fragment>
    )
}

function ModalSection(props: {
    toggle: () => void
    dataItem: LinkAccountSalesType
    coaType: string | undefined
}) {

    // hooks
    const { data, isLoading } = useAccountGroup()
    const api = useApi()
    const { toggle } = useContext(Render)

    const onSubmit = (value: SelectedCoaType, dataItem: LinkAccountSalesType) => {
        return new Promise<void>((resolve) => {
            toast.promise(
                api.post(`/linkedaccount/update`, {
                    linked_account_code: dataItem.linked_account_code,
                    linked_account_name: dataItem.linked_account_name,
                    description: dataItem.description,
                    coa_id: value.coa_id
                }), {
                    loading: "Loading...",
                    success: (res) => res.data.message,
                    error: (err) => {
                        if (err.response && err.response.status === 403) {
                          return err.response.data.detail.message || "Bad Request";
                        } else {
                          return "An error occurred";
                        }
                      }
                }
            ).then(() => {
                props.toggle()
                toggle()
            }).catch(() => {}).finally(resolve)
        })
    }

   return (
        <Modal isOpen title="" size="full" closeModal={props.toggle}>
                <Modal.Body>
                    {isLoading || data.length === 0 ? (
                        <section className="my-5">
                            <Loading loading={isLoading} />
                        </section>
                    ) : (
                        <Fragment>
                            <section className="flex justify-center mb-3">
                                <div className="border-b text-xl font-bold">ACCOUNT</div>
                            </section>

                            <CoaList
                                data={data}
                                onAddCoa={(_,__,coa) => {
                                    onSubmit(coa, props.dataItem)
                                }}
                                defaultGroup={props.coaType}
                            />
                        </Fragment>
                    )}
                </Modal.Body>
            </Modal>
    )
}