import { Button } from "components"
import { TabRegisterPath } from "../types"

type TabRegisterProps = {
  activeTab: string
  setActiveTab: (path: string) => void 
}

type Tabs = {
  label: string
  path: TabRegisterPath
  permission: string
}

export function TabNavRegister({ activeTab, setActiveTab }: TabRegisterProps) {

  const tabs: Tabs[] = [
    { label: "REGISTER GROUP", path: "items-group", permission: "IR01" },
    { label: "TRANSFER", path: "items-transfer", permission: "IR02" },
    { label: "ADJUSTMENT", path: "items-adjustment", permission: "IR03" },
    { label: "COUNT", path: "items-count", permission: "IR04" },
    { label: "BUILD ITEM", path: "items-build", permission: "IR05" },
    { label: "REQUEST FROM", path: "items-proces", permission: "IR06" },
  ]

  return (
    <section className="grow flex items-stretch overflow-x-auto">
      {tabs.map((tab) => (
        <Button
          key={tab.path}
          permission={tab?.permission}
          type="button"
          color="ghost"
          size="sm"
          className={`grow bg-[#3c3c3c] border-y-0 border-x no-animation hover:bg-primary hover:text-black whitespace-nowrap uppercase ${tab.path === activeTab ? "bg-primary text-black" : "text-white"}`}
          onClick={() => setActiveTab(tab.path)}
        >
          {tab.label}
        </Button>
      ))}
    </section>
  )
}
