// React
import React, { Fragment, useState } from "react"

// Components
import { BaseTable, Card, Input, Loading, PaginationData, TableNumber } from "../../../../components"
import { Create, Delete, Detail, Update } from "./components"

// Utils
import { useList } from "./utils"
import { PaginationState } from "types"
import { default_pagination_value } from "utils"
import { debounce } from "lodash"
import { IsActive } from "./components/IsActive"
import { TaxCodeType } from "./types"


export default function TaxCode() {
  // Hooks
  const [pagination, setPagination] = useState<PaginationState>(default_pagination_value)
  const { data, isLoading, refetch } = useList(pagination)
  const onDebounce = debounce((e: any) => setPagination(prev => {
    return {
      ...prev,
      keyword: e.target.value
    }
  }))

  const dataList: TaxCodeType[] = data.items

  return (
    <section className="container pt-5 gap-5 flex flex-col">
      <Card>
        <Card.Body className="flex flex-row items-center justify-between !text-xl font-bold">
          <div className="bg-black p-3 text-white rounded-lg"> TAX CODE</div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body className="flex-row justify-end items-center">
          <Create onSuccess={refetch} />
        </Card.Body>

        <Card.Body>
          <div className="flex">
            <div className="mb-3">
              <Input
                label="SEARCH"
                className="md:max-w-[300px] w-full"
                placeholder="Search..."
                onChange={onDebounce}
              />
            </div>
          </div>
            <BaseTable>
              <thead>
                <tr>
                  <th>NO</th>
                  <th>CODE</th>
                  <th>NAME</th>
                  <th>TYPE</th>
                  <th>RATE</th>
                  <th>COA COLLECT</th>
                  <th>COA PAID</th>
                  <th>NOTE</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {isLoading || dataList.length === 0 ? (
                  <tr>
                    <td colSpan={9} className="text-center">
                      <Loading loading={isLoading} errorText="No data available" />
                    </td>
                  </tr>
                ) : (
                  <Fragment>
                    {dataList.map((item, key) => (
                      <tr key={key}>
                        <td>{TableNumber({ dataLength: data.pagination_data.size, pageCurrent: pagination.page, index: key, limit: pagination.limit, totalData: pagination.total })}</td>
                        <td>{item.tax_code}</td>
                        <td>{item.tax_name}</td>
                        <td>{item.type_tax_name}</td>
                        <td>{item.rate}</td>
                        <td>{item.coa_collect_grp_code}-{item.coa_collect_coa_code} {item.coa_collect_coa_name}</td>
                        <td>{item.coa_paid_grp_code}-{item.coa_paid_coa_code} {item.coa_paid_coa_name}</td>
                        <td>{item.note}</td>
                        <td className="flex items-center">
                          <Detail item={item} />
                          <Update item={item} onSuccess={refetch} />
                          <Delete item={item} onSuccess={refetch} />
                          <IsActive id={item} refetch={refetch} />
                        </td>
                      </tr>
                    ))}
                  </Fragment>
                )}
              </tbody>
            </BaseTable>
          <div className="mt-5">
            <PaginationData data={data} pagination={pagination} setPagination={setPagination} />
          </div>
        </Card.Body>
      </Card>
    </section>
  )
}