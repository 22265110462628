import { Fragment} from "react"
import { Create } from "./components/Item/components/Create"
import { AccountHeaderType } from "../../types"
import { useListAccount } from "../../utils"
import { BaseTable } from "components"

export function ChildItem() {

  const { data } = useListAccount()

  const coaData: any = {};
  data.forEach(item => {
    coaData[item.linked_account_code] = {
      coa_code: item.coa_code || '', 
      coa_name: item.coa_name || '',
      group_coa_code: item.group_coa_code || ''   
    };
  });

  const dataAccountLink: AccountHeaderType[] = [
    {
      Header: "BANKING LINKED ACCOUNTS",
      LinkAccount: [
        {
          linked_account_id: 1,
          linked_account_code: "LA01",
          linked_account_name: "Banking Linked Accounts",
          description: "Equity Account for Current Earning",
          account_coa: "3"
        },
        {
          linked_account_id: 2,
          linked_account_code: "LA02",
          linked_account_name: "Banking Linked Accounts",
          description: "Equity Account for Retained  Earning",
          account_coa: "3"
        },
        {
          linked_account_id: 3,
          linked_account_code: "LA03",
          linked_account_name: "Banking Linked Accounts",
          description: "Equity Account for Historical Balancing ",
          account_coa: "3"
        },
      ]
    },
    {
      Header: "SALES LINKED ACCOUNTS",
      LinkAccount: [
        {
          linked_account_id: 4,
          linked_account_code: "LA04",
          linked_account_name: "Sales Linked Accounts",
          description: "Asset Account for Tracking AR",
          account_coa: "1"
        },
        {
          linked_account_id: 5,
          linked_account_code: "LA05",
          linked_account_name: "Sales Linked Accounts",
          description: "Bank Acount for Customer Receipts",
          account_coa: "1"
        },
        {
          linked_account_id: 6,
          linked_account_code: "LA06",
          linked_account_name: "Sales Linked Accounts",
          description: "Liability for Customer Deposits",
          account_coa: "2"
        },
      ]
    },
    {
      Header: "PURCHASE LINKED ACCOUNTS",
      LinkAccount: [
        {
          linked_account_id: 7,
          linked_account_code: "LA07",
          linked_account_name: "Purchase Linked Accounts",
          description: "Liability Account for Tracking AP",
          account_coa: "2"
        },
        {
          linked_account_id: 8,
          linked_account_code: "LA08",
          linked_account_name: "Purchase Linked Accounts",
          description: "Bank Account for Buying Bills",
          account_coa: "1"
        },
        {
          linked_account_id: 9,
          linked_account_code: "LA09",
          linked_account_name: "Purchase Linked Accounts",
          description: "Asset Account for Supplier Deposit",
          account_coa: "1"
        },
      ]
    },
    {
      Header: "DEFAULT TAX ACCOUNTS",
      LinkAccount: [
        {
          linked_account_id: 10,
          linked_account_code: "TAXC",
          linked_account_name: "Default Tax Accounts",
          description: "Default Tax Collected from Customer",
          account_coa: "2"
        },
        {
          linked_account_id: 11,
          linked_account_code: "TAXP",
          linked_account_name: "Default Tax Accounts",
          description: "Default Tax Paid to Vendor/supplier",
          account_coa: "2"
        },
      ]
    },
  ]

  return (
    <Fragment>
      <div className="border">
        {dataAccountLink.map((data, key) => (
          <div key={key}>
            <div className="border p-4">
              <span className="font-bold">{data.Header}</span>
            </div>
            <div>
              <div className="p-4">
                <BaseTable className="table">
                  <thead>
                    <tr>
                      <th className="border">CODE</th>
                      <th className="border" colSpan={2}>DESCRIPTION</th>
                      <th className="border">NUMBER</th>
                      <th className="border">COA NAME</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.LinkAccount.map((item, index) => (
                      <tr key={index}>
                        <td className="border w-[100px]">{item?.linked_account_code}</td>
                        <td className="border w-[300px]">{item?.description}</td>
                        <td className="border text-center w-[100px]">
                          <Create 
                            dataItem={item} 
                            coaType={item?.account_coa}
                            />
                        </td>
                        <td className="border w-[200px]">{ (coaData[item.linked_account_code]?.group_coa_code) ? `${coaData[item.linked_account_code]?.group_coa_code}-${coaData[item.linked_account_code]?.coa_code}` : ''}</td>
                        <td className="border w-[200px]">{coaData[item.linked_account_code]?.coa_name}</td>
                      </tr>
                    ))}
                  </tbody>
                </BaseTable>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  )
}