import {
  BaseTable,
  Card,
  CommandButton,
  HeaderMenu,
  Label,
  Loading,
  PaginationData,
  Select
} from "components"
import { createContext, useEffect, useState } from "react"
import { default_pagination_value } from "utils"
import { useDropdownEmployee } from "../CustomerOrder/utils/hooks/useDropdownEmployee"
import { useReturnItemsList } from "../ReturnItems/utils/hooks"
import { RowApproveInvoice } from "./components"
import { FilterApprovalReturnItems } from "./types"
import { APPROVAL_OPTIONS } from "./utils/vars"

type ContextApproveInvoiceProps = {
  refetch: () => void
}

export const ContextApproveInvoice = createContext<ContextApproveInvoiceProps>({
  refetch: () => {}
})

export default function ApproveReturnItems() {
  const [pagination, setPagination] = useState<FilterApprovalReturnItems>({
    ...default_pagination_value,
    size: 0,
    pages: 1,
    total: 0
  })
  const [searchData, setSearchData] = useState<number>(1)
  const { dropdown } = useDropdownEmployee()
  const { data, paginate, isLoading, refetch } = useReturnItemsList({
    Approval: searchData,
    page: pagination.page,
    limit: pagination.limit
  })

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      size: paginate?.size ?? 0,
      pages: paginate?.pages ?? 1,
      total: paginate?.total ?? 0
    }))
  }, [paginate])

  return (
    <ContextApproveInvoice.Provider value={{ refetch: refetch }}>
      <section className="container my-2">
        <section>
          <HeaderMenu title="APPROVAL RETURN ITEMS" />

          <section>
            <section className="mt-2">
              <Card>
                <Card.Body>
                  <div className="justify-end flex items-center">
                    <Label className="mr-2">APPROVAL</Label>
                    <Select
                      className="lg:w-[200px]"
                      placeholder="Select Approval"
                      options={APPROVAL_OPTIONS}
                      value={APPROVAL_OPTIONS.find(
                        (item) => Number(item.value) === searchData
                      )}
                      onChange={(e) => setSearchData(Number(e?.value!))}
                    />
                  </div>
                </Card.Body>
              </Card>
            </section>
          </section>

          <section className="mt-2">
            <Card>
              <Card.Body>
                <div className="overflow-x-auto">
                  <BaseTable>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th colSpan={2}>RETURN ID</th>
                        <th>DATE</th>
                        <th>CUSTOMER</th>
                        <th>REF INVOICE ID#</th>
                        <th>LOCATION</th>
                        <th>MEMO</th>
                        <th>STATUS APPROVE</th>
                        <th>DATE APPROVE</th>
                        <th>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading || !data?.length ? (
                        <tr>
                          <td colSpan={12} className="text-center">
                            <Loading
                              loading={isLoading}
                              errorText={isLoading ? "" : "No data available"}
                            />
                          </td>
                        </tr>
                      ) : (
                        data?.map((item, key) => {
                          return (
                            <RowApproveInvoice
                              key={item.invoice_id}
                              pagination={pagination}
                              employees={dropdown}
                              data={item}
                              index={key}
                            />
                          )
                        })
                      )}
                    </tbody>
                  </BaseTable>
                  <div className="mt-5">
                    <PaginationData
                      data={{
                        pagination_data: {
                          size: paginate?.size
                        }
                      }}
                      pagination={pagination}
                      setPagination={setPagination}
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </section>

          <section className="mt-2">
            <Card>
              <Card.Body className="flex flex-col gap-5">
                <section className="flex flex-wrap justify-between items-center gap-3">
                  <section className="flex flex-wrap gap-3">
                    <CommandButton actiontype="help" />
                    <CommandButton actiontype="print" />
                    <CommandButton actiontype="email" />
                    <CommandButton actiontype="export" />
                  </section>
                </section>
              </Card.Body>
            </Card>
          </section>
        </section>
      </section>
    </ContextApproveInvoice.Provider>
  )
}
