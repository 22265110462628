import { Fragment } from "react"
import { ActionButton, Loading, Modal } from "../../../../../../../../../components"
import { FormSection } from "../../../../FormSection"
import { FormProvider, useForm } from "react-hook-form"
import type { ListBank } from "../../../../../types"
import { useToggle } from "../../../../../../../../../utils"
import { useDetail } from "../utils"

export function Detail(props: { id: ListBank }) {
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <ActionButton.Detail className="join-item" onClick={toggle} />
      {isActive && <ModalSection id={props.id} toggle={toggle} />}
    </Fragment>
  )
}

function ModalSection(props: {
  id: ListBank
  toggle: () => void
}) {
  const { data, dataError, isLoading } = useDetail()

  return (
    <Modal isOpen title="DETAIL COA TYPE" size="xl" closeModal={props.toggle}>
      {isLoading || dataError ? (
        <Loading errorText={dataError} loading={isLoading} />
      ) : (
        <DataForm data={data!} id={props.id} toggle={props.toggle} />
      )}
    </Modal>
  )
}

function DataForm(props: {
  data: ListBank
  id: ListBank
  toggle: () => void
}) {
  const methods = useForm({ defaultValues: props.id })

  return (
    <FormProvider {...methods}>
      <FormSection isDetail action="DETAIL" />
    </FormProvider>
  )
}