import { Fragment, useState } from "react";
import { Delete, Update } from "./ActionSection";
import { BaseTable, Input, Loading, PaginationData, TableHeaderSort, TableNumber } from "components";
import { PaginationState} from "types";
import { convertNumber, default_pagination_value } from "utils";
import { useList } from "../utils";
import { debounce } from "lodash"
import { PicturesSection } from "./PicturesSection";
import { ReactBarcode } from "react-jsbarcode";
import moment from "moment";

export function TableSection() {
    const [pagination, setPagination] = useState<PaginationState>(default_pagination_value)
    const { data, isLoading, refetch } = useList(pagination)
    const onDebounce = debounce((e: any) => setPagination(prev => {
        return {
            ...prev,
            keyword: e.target.value
        }
    }))

    const header: any[] = [
        { title: "OPENING DATE", sort: true },
        { title: "BARCODE", sort: true },
        { title: "ITEM NAME", sort: true },
        { title: "PICTURES", sort: false },
        { title: "STOCK", sort: true },
        { title: "UNIT", sort: true },
        { title: "AVERAGE PRICE UNIT", sort: true },
        { title: "LOCATION", sort: true },
    ]

    const sortMapping: Record<string, string> = {
        'OPENING DATE': 'date',
        'BARCODE': 'barcode',
        'ITEM NAME': 'product',
        'STOCK': 'soh',
        'UNIT': 'unit',
        'AVERAGE PRICE UNIT': 'price',
        'LOCATION': 'location',
    }

    const handleSort = (item: string, sortType: string) => {
        const sortParam = sortMapping[item] ? `${sortMapping[item]}:${sortType}` : '';
        setPagination({ ...pagination, sort: sortParam, page: 1 })
    }

    return (
        <Fragment>
            <div className="flex">
                <div className="mb-3">
                    <Input
                        label="SEARCH"
                        className="md:max-w-[300px] w-full"
                        placeholder="Search..."
                        onChange={onDebounce}
                    />
                </div>
            </div>
            <BaseTable className="border-collapse">
                <thead>
                    <tr>
                        <th>NO</th>
                        <TableHeaderSort
                            title={header}
                            onSort={(item, sortType) => handleSort(item, sortType)}
                        />
                        <th>ACTION</th>
                    </tr>
                </thead>
                <tbody>
                    {isLoading || data?.payload?.length === 0 ? (
                        <tr>
                            <td colSpan={9} className="text-center">
                                <Loading loading={isLoading} errorText="No data available" />
                            </td>
                        </tr>
                    ) : data?.payload?.map((item: any, key) => (
                        <tr key={key}>
                            <td>{TableNumber({ dataLength: data?.pagination_data?.size, pageCurrent: pagination.page, index: key, limit: pagination?.limit, totalData: pagination.total })}</td>
                            <td>{moment(item.transaction_date).format("DD/MM/YYYY")}</td>
                            <td>
                                <div className="w-10px">
                                <ReactBarcode
                                    options={{
                                        width: 0.5,
                                        fontSize: 11,
                                        height: 23,
                                    }}
                                    value={`${item.product_barcode}`}
                                />
                                </div>
                            </td>
                            <td>{item.product_name}</td>
                            <td >
                                <div className="w-[400px] lg:w-[200px]">
                                    <PicturesSection dataPicture={item?.picture} items={1} className="h-[100px] w-full" />
                                </div>
                            </td>
                            <td >{convertNumber(Number(item.soh)).intoNormalAmount}</td>
                            <td>{item.unit_name}</td>
                            <td className="text-right">{item.av_price_unit ? convertNumber(Number(item.av_price_unit)).intoCurrency : '0'}</td>
                            <td>{item.location_name}</td>
                            <td>
                                <div className="join flex justify-center">
                                    <Update data={item} />
                                    <Delete id={item.opening_stock_id} refetch={refetch} />
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </BaseTable>
            <div className="mt-5">
                <PaginationData data={data} pagination={pagination} setPagination={setPagination} />
            </div>
        </Fragment>
    )
}