import { useContext } from "react"
import { Card } from "../../../../components"
import { ChildItem} from "./components"
import { Render, RenderProvider } from "./utils"

export default function LinkAccount() {
  return (
    <RenderProvider>
     <section className="container pt-5  gap-5 flex flex-col">
      <Card>
        <Card.Body className="flex flex-row items-center justify-between !text-xl font-bold">
          <div className="bg-black p-3 text-white rounded-lg">LINK ACCOUNT GENERAL</div>
        </Card.Body>
      </Card>
        <Card>
          <Card.Body>
            <div className="overflow-x-auto">
            <TableContent/>
            </div>
          </Card.Body>
        </Card>
      </section>
    </RenderProvider>
  )
}

function TableContent() {
  const { render } = useContext(Render)

  if (render) {
    return <ChildItem />
  } else {
    return <div />
  }
}