import type { MenuType } from "../types"

export const purchaseMenu = {
  menu: [
    {
      label: "REGISTER",
      link: "/purchase/register",
      permission: "PR"
    },
    {
      label: "TRANSACTION",
      link: "#",
      permission: "PT",
      children: [
        { label: "PURCHASES REGISTER", link: "/purchase/transaction/purchase-register", permission: "PT01" },
        { label: "PURCHASES ORDER", link: "/purchase/transaction/purchase-order", permission: "PT02" },
        { label: "REQUEST PURCHASE", link: "/purchase/transaction/request-purchase", permission: "PT03" },
        { label: "REJECT ITEMS", link: "/purchase/transaction/reject-items", permission: "PT04" },
        { label: "APPROVE PO", link: "/purchase/transaction/approve-po", permission: "PT09" },
        { label: "APPROVE BILLS", link: "/purchase/transaction/approve-bills", permission: "PT08" },
        { label: "APPROVE DEPO VENDOR", link: "/purchase/transaction/approve-depo-vendor", permission: "PT10" },
        { label: "TRANSACTION JOURNAL", link: "/purchase/transaction/trans-journal", permission: "PT06" },
        { label: "DASHBOARD", link: "/purchase/transaction/dashboard", permission: "PT07" },
      ]
    },
    {
      label: "REPORT",
      link: "#",
      permission: "PP",
      children: [
        { label: "AGING SUMMARY", link: "/purchase/report/aging-summary", permission: "PP01" },
        { label: "AGING DETAIL", link: "/purchase/report/ageng-detail", permission: "PP02" },
        { label: "PURCHASE SUMMARY", link: "/purchase/report/purchase-summary", permission: "PP03" },
        { label: "PURCHASE DETAIL", link: "/purchase/report/purchase-detail", permission: "PP04" },
        { label: "ANALYSE PURCHASE - PERIODE", link: "/purchase/report/analyse-purchase-periode", permission: "PP05" },
        { label: "ANALYSE PURCHASE - COMPARATION", link: "/purchase/report/analyse-purchase-comparation", permission: "PP06" },
        { label: "ANALYSE PURCHASE - MONTHLY", link: "/purchase/report/analyse-purchase-monthly", permission: "PP07" },
        { label: "VENDOR PURCHASE SUMMARY", link: "/purchase/report/vendor-purchase-summary", permission: "PP08" },
        { label: "VENDOR PURCHASE DETAIL", link: "/purchase/report/vendor-purchase-detail", permission: "PP09" },
        { label: "BILL TRANSACTION SUMMARY", link: "/purchase/report/bill-summary", permission: "PP10" },
        { label: "BILL TRANSACTION DETAIL", link: "/purchase/report/bill-detail", permission: "PP11" },
        { label: "SUPPLIER PAYMENT", link: "/purchase/report/supplier-payment", permission: "PP12" },
        { label: "SUPPLIER PAYMENT HISTORY", link: "/purchase/report/supplier-payment-history", permission: "PP13" },
        { label: "PURCHASE PAYABLE JOURNAL", link: "/purchase/report/purchase-payable-journal", permission: "PP14" },
        { label: "DASHBOARD", link: "/purchase/report/dashboard", permission: "PP15" },
      ]
    },
    {
      label: "JOURNAL",
      link: "/purchase/journal",
      // permission: "PJ",
      permission: "PT" // BELUM
    },
    {
      label: "FIND",
      link: "/purchase/find",
      // permission: "PF"
      permission: "PT" // BELUM
    }
  ] as MenuType[]
}