import { BaseTable, Loading, PermissionLink } from "components"
import { convertDate } from "pages/Inventori/Transaction/TransactionJournal/utils"
import { TableSectionProps } from "pages/Purchase/Register/types"
import { IoSendSharp } from "react-icons/io5"
import { convertNumber } from "utils"

export const TableSection = ({ data, isLoading }: TableSectionProps) => {
  const approvalChecked = (data: number) => {
    if(data === 1) { return "PENDING" }
    if(data === 2) { return "REVISI" }
    if(data === 3) { return "REJECT" }
    if(data === 4) { return "APPROVE" }
  }

  return (
    <BaseTable className="border-transparent">
      <thead>
        <tr className="uppercase">
            <th>No</th>
            <th colSpan={2}>Bill Id#</th>
            <th>Ref Po Id#</th>
            <th>Date</th>
            <th>Vendor</th>
            <th>Status<br />Approval</th>
            <th>Qty<br />Delivered</th>
            <th>Location</th>
            <th>Subtotal</th>
            <th>Payment</th>
            <th>Balance<br />Owing</th>
            {/* <th>Action</th> */}
        </tr>
      </thead>
      <tbody>
        {!data.length || isLoading 
            ? <tr><td colSpan={13} className="text-center"><Loading loading={isLoading} errorText={isLoading ? "" : "No data available"} /></td></tr>
            : data.map((val, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>
                  <PermissionLink permission="PR053" to={`/purchase/transaction/enter-bills/${val.bill_id}`}>
                    <IoSendSharp />
                  </PermissionLink>
                </td>
                <td>{val?.trx_code ?? "-"}</td>
                <td>{val?.trx_code_po ?? "-"}</td>
                <td>{val?.transaction_date ? convertDate(val?.transaction_date) : "-"}</td>
                <td>{val?.vendor_name ?? "-"}</td>
                <td>{val?.approve_status ? approvalChecked(val?.approve_status) : "-"}</td>
                <td className="text-center">{val?.qty_delivered ? convertNumber(val?.qty_delivered)?.intoNormalAmount : "-"}</td>
                <td>{val?.location_name ?? "-"}</td>
                <td className="text-right">{val?.sub_total ? convertNumber(val?.sub_total)?.intoCurrency : "0"}</td>
                <td className="text-right">{val?.payment ? convertNumber(val?.payment)?.intoCurrency : "0"}</td>
                <td className="text-right">{val?.balance_owing ? convertNumber(val?.balance_owing)?.intoCurrency : "0"}</td>
                {/* <td>
                  {val?.approve_status === 1 && <div className="flex justify-center">
                    <ActionButton.Delete permission="PR054" />
                  </div>}
                </td> */}
              </tr>
            ))
        }
      </tbody>
    </BaseTable>
  )
}