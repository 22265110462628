// React
import { Fragment, useEffect } from "react"

// Components
import { Button, Card, CommandButton, DateInput, HeaderMenu, Input, Loading, Select } from "../../../components"

// Utils
import { useCoaGroup, useFilter, useList } from "./utils"
import { IoCalendarOutline } from "react-icons/io5"
import { useLocation } from "react-router-dom"

interface SelectOptionType {
  label: string
  value: string
  permission: string
}

export default function Find() {
  // Hooks
  const { isLoading } = useCoaGroup()
  const dataTab = [
    { label: "Accounts", value: "", permission: "AF" },
    { label: "Finance", value: "1", permission: "AF" },
    { label: "Sales", value: "2", permission: "AF" },
    { label: "Purchases", value: "3", permission: "AF" },
    { label: "Inventory", value: "4", permission: "AF" },
    { label: "Cards", value: "5", permission: "AF" },
    { label: "Journals", value: "6", permission: "AF" }
]

  if (isLoading) {
    return (
      <section className="min-h-[500px]">
        <Loading loading />
      </section>
    )
  } else {
    return <ViewSection dataFilter={dataTab} />
  }
}

export function ViewSection(props: { dataFilter: SelectOptionType[] }) {
  const location = useLocation()
  const path = location?.pathname.split('/');
  const firstPath = path[1];
  // Hooks
  const { value, setValue } = useFilter(
    firstPath === "accounts" 
    ? "" 
    : firstPath === "finance" 
    ? "1" 
    : firstPath === "sales" 
    ? "2" 
    : firstPath === "purchase" 
    ? "3" 
    : firstPath === "inventory" 
    ? "4" 
    : firstPath === "cards" 
    ? "5" 
    : props.dataFilter[0].value
  )
  const { isLoading, refetch } = useList(undefined, value)

  useEffect(() => {
    refetch()

    // eslint-disable-next-line
  }, [value])

  return (
    <Fragment>
      <section className="container my-5">
        {isLoading ? (
          <Loading loading />
        ) : (
          <section>
            <HeaderMenu title="FIND RECORDS" />

            <section className="grow flex items-stretch overflow-x-auto">
              {props.dataFilter.map((item, key) => (
                <Button
                  key={key}
                  permission={item?.permission}
                  type="button"
                  color="ghost"
                  size="sm"
                  className={`grow bg-[#3c3c3c] border-y-0 border-x no-animation hover:bg-primary hover:text-black whitespace-nowrap uppercase ${item.value === value ? "bg-primary text-black" : "text-white"}`}
                  onClick={() => setValue(item.value)}
                >
                  {item.label}
                </Button>
              ))}
            </section>

            <section className="mt-2">
              <Card>
                <Card.Body>
                  <section className="flex justify-between items-center">
                    <div className="w-[80%]">
                      <div className="w-full lg:flex justify-center lg:justify-start items-center mb-3">
                        <div className="label-text pb-1 font-bold pe-2 w-[110px]">Search by</div>
                        <div>
                          <Select controlClass="w-[265px]"  />
                        </div>
                        <Input className="ml-2" inputClass="w-[326px]" />
                      </div>
                      <div className="w-full lg:flex justify-center lg:justify-start items-center">
                        <div className="label-text pb-1 font-bold pe-2 w-[85px]">Date From</div>
                        <DateInput className="!rounded-r-none" placeholderText="Date from" onChange={() => {}} />

                        <div>
                          <Button
                            type="button"
                            color="ghost"
                            className="rounded-l-none rounded-r-lg border-1 border-gray-300 border-l-0">
                            <IoCalendarOutline fontSize={18} />
                          </Button>
                        </div>

                        <div className="mt-3 lg:mt-0 lg:px-2 pb-1 label-text font-bold w-[75px]">Date To</div>
                        <DateInput className="!rounded-r-none" placeholderText="Date To" onChange={() => {}} />

                        <div>
                          <Button
                            type="button"
                            color="ghost"
                            className="rounded-l-none rounded-r-lg border-1 border-gray-300 border-l-0">
                            <IoCalendarOutline fontSize={18} />
                          </Button>
                        </div>
                      </div>
                    </div>

                    <div className="w-[20%] flex justify-end">
                      <CommandButton actiontype="advanced" />
                    </div>
                  </section>
                </Card.Body>
              </Card>
            </section>

            <section className="mt-2">
              <Card>
                <Card.Body className="flex-row justify-between items-center">
                  <Card.Title>CONTENT</Card.Title>
                </Card.Body>
              </Card>
            </section>

            <section className="mt-2">
              <Card>
                <Card.Body className="flex flex-col gap-5">
                  <section className="flex flex-wrap justify-between items-center gap-3">
                    <section className="flex flex-wrap gap-3">
                      <CommandButton actiontype="help" />
                      <CommandButton actiontype="print" />
                      <CommandButton actiontype="email" />
                      <CommandButton actiontype="export" />
                    </section>
                  </section>
                </Card.Body>
              </Card>
            </section>
          </section>
        )}
      </section>
    </Fragment>
  )
}