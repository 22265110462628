// React
import { Fragment, useContext, useEffect } from "react"

// Components
import { Button, PermissionLink } from "components"

// Configs
import { iconMenu } from "configs"

// Contexts
import { ThemeContext } from "contexts"

// Icons
import { IoLogOutOutline } from "react-icons/io5"
import { TbMoon, TbSun } from "react-icons/tb"

// Third-Party Libraries
import { type Location, Link, Outlet, useLocation, useNavigate } from "react-router-dom"

// Types
import type { MenuType, RouteType } from "types"

// Utils
import { usePermissionRoute, useHandleAuth } from "utils"

export function Layout(props: {
  module_menu: {
    children?: {
      label: string
      link: string
      permission?: string
    }[]
    label: string
    link: string
    permission?: string
  }[]
  routeList?: {
    [key: string]: RouteType[]
  }
}) {
  // Hooks
  const handleAuth = useHandleAuth()
  const { userTokenData } = useHandleAuth()
  const userToken: any = userTokenData()
  const navigate = useNavigate()
  const location = useLocation()
  const permissionRoute = usePermissionRoute()
  const { theme, handleTheme } = useContext(ThemeContext)

  // Vars
  const currentDate = new Date().toLocaleString('en-us', { day: 'numeric', month: 'long', year: 'numeric' })

  useEffect(() => {
    if (!localStorage?.getItem("token")) {
      navigate("/login", { replace: true })
    }

    // eslint-disable-next-line
  }, [])

  const handleClick = () => {
    // @ts-ignore
    document.activeElement?.blur()
  }

  return (
    <Fragment>
      <header className="w-full fixed top-0 z-10">
        <section className="bg-base-200">
          <section className="p-3 flex flex-wrap justify-between items-center">
            <Link to="/">
              <button type="button" title="Home" className="flex items-center font-semibold ui-text-black gap-1">
                <div className="size-8 bg-contain logo"/>
                <span className="lg:block hidden">FLYING HAWK INDONESIA HawkDev version 1.03</span>
              </button>
            </Link>

            <div className="uppercase ml-[-50px] sm:block hidden"><b>{userToken ? userToken?.company_name : ""}</b></div>

            <div className="flex gap-4 items-center">
              <section className="flex items-center gap-1">
                <TbSun />

                <input
                  type="checkbox"
                  className="toggle"
                  checked={theme === "dark"}
                  onChange={handleTheme}
                />

                <TbMoon />
              </section>

              <section className="sm:block hidden">
                <div className="flex gap-2">
                  <small className="text-[11px]">USERNAME</small>
                  <small className="text-[11px]">: {userToken ? userToken.sub : ''}</small>
                </div>
                <div className="flex gap-2">
                  <small className="text-[11px]">DATE</small>
                  <small className="text-[11px]">: {currentDate}</small>
                </div>
              </section>

              <button type="button" className="btn btn-sm btn-ghost bg-base-300" onClick={handleAuth.logout}>
                <IoLogOutOutline className="w-5 h-5" />
              </button>
            </div>
          </section>

          <section className="flex items-stretch">
            {iconMenu.map((item, key) => (
              <PermissionLink
                key={key}
                to={item.link}
                className="!w-fit last:border-r-0 border-r border-l-0 border-y-0 border-black dark:border-white"
                permission={item.permission}
              >
                <Button
                  type="button"
                  className="btn-ghost no-animation p-2 bg-white dark:bg-black"
                  title={item.label}
                >
                  <item.icon
                    size={24}
                    className="text-black dark:text-white"
                  />
                </Button>
              </PermissionLink>
            ))}


            <section className="relative grow flex items-stretch overflow-x-auto no-scrollbar">
              {props.module_menu.map((item, key) => {
                // Vars
                const currentLink = props.routeList && props.routeList[item.label].find(item => {
                  return item.permission && permissionRoute.checkPermission(item.permission, { disableAlert: true })
                })

                return (
                  <section
                    key={key}
                    className="grow border-r border-white bg-black text-white"
                  >
                    {item.children ? (
                      <Menu
                        data={item}
                        location={location}
                        handleClick={handleClick}
                      />
                    ) : (
                      <PermissionLink
                        permission={currentLink?.permission}
                        to={currentLink?.path && item.link !== "#" ? currentLink.path : item.link}
                      >
                        <Button
                          type="button"
                          color="ghost"
                          className={`w-full no-animation hover:bg-primary hover:text-black ${location.pathname.includes(item.link) && "bg-primary text-black"}`}
                        >
                          {item.label}
                        </Button>
                      </PermissionLink>
                    )}
                  </section>
                )
              }
            )}
            </section>

            <Button type="button" className="btn-ghost no-animation p-2 bg-base-200" title="About">
              <div className="size-8 bg-contain logo" />
            </Button>
          </section>
        </section>
      </header>

      <main className="mt-[108px]">
        <Outlet />
      </main>
    </Fragment>
  )
}

function Menu(props: {
  alignRight?: boolean
  data: MenuType
  location: Location
  handleClick: () => void
}) {
  return (
    <div className="dropdown w-full">
      <Button
        tabIndex={0}
        size="sm"
        color={props.location.pathname.includes(props.data.label.toLowerCase()) ? "primary" : "ghost"}
        className={`!h-[48px] w-full no-animation hover:bg-primary hover:text-black ${props.location.pathname.includes(props.data.link) && "bg-primary ui-text-black"}`}
      >
        {props.data.label}
      </Button>

      <ul
        tabIndex={0}
        className="!fixed dropdown-content z-10 menu p-2 shadow bg-white dark:bg-black ui-text-black rounded-box !w-[200px] overflow-y-auto !flex-nowrap rounded-none"
        style={{ maxHeight: "500px" }}
      >
        {props.data.children!.map((item, key) => (
          <li
            key={key}
            onClick={props.handleClick}
            className={`border-b border-black dark:border-white ${key === 0 && "border-t"}`}
          >
            <Link to={item.link}>{item.label}</Link>
          </li>
        ))}
      </ul>
    </div>
  )
}