import { Input, Textarea } from "../../../../../../components"
import { Controller, useFormContext } from "react-hook-form"
import type { FormBank } from "../../types"

export function FormSection(props: {
  isDetail?: boolean
  isUpdate?: boolean
  action?: string
}) {

  const { control } = useFormContext<FormBank>()

  return (
    <section>
      <Controller
        control={control}
        name="bank_code"
        render={({ field, fieldState }) => <Input {...field} label="CODE" value={field.value ?? ""} error={fieldState.error?.message} disabled={props.action === "DETAIL" || props.action === "DELETE"} />}
      />

      <Controller
        control={control}
        name="bank_name"
        render={({ field, fieldState }) => <Input {...field} label="BANK" value={field.value ?? ""} error={fieldState.error?.message} disabled={props.action === "DETAIL" || props.action === "DELETE"} />}
      />

      <Controller
        control={control}
        name="description"
        render={({ field, fieldState }) => <Textarea {...field} label="DESCRIPTION" value={field.value ?? ""}  error={fieldState.error?.message} disabled={props.action === "DETAIL" || props.action === "DELETE"} />}
      />
    </section>
  )
}