// Pages
import InventoriRegister from "pages/Inventori/Register"
import Journal from "pages/Finance/Journal"
import Find from "pages/Accounts/Find"
import TransactionJournal from "pages/Inventori/Transaction/TransactionJournal"
import OpeningStock from "pages/Inventori/Transaction/OpeningStock"
import { CreatePage, CreatePageTwo } from "../pages/Inventori/Transaction/AddNewItem/components"
import { DetailPage, UpdatePage } from "../pages/Inventori/Transaction/AddNewItem/components/ChildItem/components/Item/components"
import { UpdateAction, CreateAction } from "pages/Inventori/Transaction/OpeningStock/components/ActionSection"

// Types
import type { RouteType } from "types"

export const inventoryRoutes: RouteType[] = [ 
  {
    path: "/inventory/register",
    element: <InventoriRegister />,
    permission: "IR"
  },
  {
    path: "/inventory/transaction/add-new-item",
    element: <CreatePage />,
    permission: "IT01"
  },
  {
    path: "/inventory/transaction/add-new-items",
    element: <CreatePageTwo />,
    permission: "IT01"
  },
  {
    path: "/inventory/transaction/add-new-item/create",
    element: <CreatePage />,
    permission: "IT011"
  },
  {
    path: "/inventory/transaction/add-new-item/update",
    element: <UpdatePage />,
    permission: "IR013"
  },
  {
    path: "/inventory/transaction/add-new-item/detail",
    element: <DetailPage />,
    permission: "IR012"
  },
  {
    path: "/inventory/transaction/transaction-jounal",
    element: <TransactionJournal />,
    permission: "IT07"
  },
  {
    path: "/inventory/transaction/opening-stock",
    element: <OpeningStock/>,
    permission: "IT08"
  },
  {
    path: "/inventory/transaction/opening-stock/create",
    element: <CreateAction />,
    permission: "IT081"
  },
  {
    path: "/inventory/transaction/opening-stock/update",
    element: <UpdateAction />,
    permission: "IT083"
  },
  {
    path: "/inventory/journal",
    element: <Journal />,
    // permission: "IJ"
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/find",
    element: <Find />,
    // permission: "IF"
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/transaction",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/transaction/item-register",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/transaction/set-item-price",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/transaction/built-inventori",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/transaction/transfer-inventory",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/transaction/adjustment-inventory",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/transaction/count-inventory",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/transaction/dashboard",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/report/list-item-summary",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/report/list-item-detail",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/report/list-item-transaction",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/report/analyse-inventory-summary",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/report/analyse-inventory-detail",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/report/build-component",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/report/inventory-count-sheet",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/report/pricing-summary",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/report/pricing-analyse",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/report/inventory-journal",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/report/stock-alert",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/report/dashboard",
    element: <div />,
    permission: "IT" // BELUM
  },
]