// React
import { Fragment, useEffect } from "react"

// Form
import { useFormContext, useWatch } from "react-hook-form"

// Types
import type { FormType } from "../../utils"

// Utils
import { useDataCode } from "./utils"

export function Code() {
  // Hooks
  const { refetch } = useDataCode()

  // Form
  const { control, formState, setValue } = useFormContext<FormType>()
  const transaction_date = useWatch({
    control,
    name: "transaction_date"
  })
  const trx_code = useWatch({
    control,
    name: "trx_code"
  })
  
  useEffect(() => {
    if (formState.defaultValues?.trx_code) {
      refetch(transaction_date).then(res => setValue("trx_code", res))
    }

    // eslint-disable-next-line
  }, [formState, transaction_date])
  
  return <Fragment>{trx_code}</Fragment>
}