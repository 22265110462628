// React
import { useContext } from "react"

// Components
import { Loading } from "components"
import { InputSection } from "./components"

// Contexts
import { DataContext, DataBillContext } from "./contexts"

// Form
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Third-Party Libraries
import { useParams } from "react-router-dom"

// Types
import type { FormType, DetailFormType } from "./utils"

// Utils
import { validationSchema, useBillDetail } from "./utils"

export default function EditEnterBill(): JSX.Element {
  // Hooks
  const { id, bill_id } = useParams()
  const { billData, data, isLoading } = useBillDetail(id!, bill_id!)

  if (isLoading || !data) {
    return (
      <Loading
        errorText="No data available"
        loading={isLoading}
      />
    )
  }

  return (
    <DataBillContext.Provider value={billData!}>
      <DataContext.Provider value={data}>
        <FormSection
          freight={data.freight}
          probildetail={data.probildetail}
          trx_code={data.trx_code}
        />
      </DataContext.Provider>
    </DataBillContext.Provider>
  )
}

export function FormSection(params: {
  freight: number
  probildetail: DetailFormType[]
  trx_code: string
}) {
  // Hooks
  const dataBill = useContext(DataContext)

  // Vars
  const datapo = dataBill.datapo

  // Form
  const defaultValues: FormType = {
    delivery_no: datapo.delivered.toString(),
    trx_code: params.trx_code,
    freight: params.freight,
    probildetail: params.probildetail,
    address: datapo.address,
    location_id: datapo.location_id,
    memo: datapo.memo,
    payment_term: datapo.payment_term,
    promise_date: datapo.promize_date,
    purchase_order_id: datapo.purchase_order_id,
    referensi: datapo.referensi,
    reminder: dataBill.reminder,
    tax_inclusive: datapo.tax_inclusive,
    transaction_date: datapo.transaction_date,
    vendor_id: datapo.vendor_id
  }
  // const onSubmit = (value: FormType) => {
  //   // Vars
  //   const final_value = {
  //     bill_id,
  //     purchase_order_id: id,
  //     trx_code: value.trx_code,
  //     vendor_id: value.vendor_id,
  //     location_id: value.location_id,
  //     address: value.address,
  //     memo: value.memo,
  //     transaction_date: value.transaction_date,
  //     referensi: value.referensi,
  //     delivery_no: value.delivery_no,
  //     freight: value.freight,
  //     promise_date: value.promise_date,
  //     reminder: value.reminder,
  //     probildetail: value.probildetail.map(item => {
  //       return {
  //         quantity_bill: item.quantity_bill,
  //         quantity_unit: item.quantity_unit,
  //         product_id: item.product_id,
  //         price_bill: item.price_bill,
  //         discount: item.discount,
  //         total: item.total,
  //         tax_id: item.tax_id
  //       }
  //     })
  //   }

  //   return new Promise<void>((resolve) => {
  //     toast.promise(
  //       api.post("/probill/add", final_value),
  //       {
  //         loading: "Loading...",
  //         success: (res) => res.data.message,
  //         error: (err) => err.response.data.detail?.message ?? err.response.data.message
  //       }
  //     ).then(() => {
  //       navigate(-1)
  //     }).catch(() => {}).finally(resolve)
  //   })
  // }
  const methods = useForm<FormType>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <FormProvider {...methods}>
      <InputSection
        // onSubmit={methods.handleSubmit(onSubmit)}
      />
    </FormProvider>
  )
}