import { ActionButton, BaseTable, Button, Loading } from "components"
import moment from "moment"
import { APPROVAL } from "pages/Sales/Register/utils/vars"
import { IoSendSharp } from "react-icons/io5"
import { Link, useNavigate } from "react-router-dom"
import { convertNumber } from "utils"
import { useDepositCustomerListBySo } from "../../../utils/hooks"

type TablePaymentDepositProps = {
  salesOrderId?: number
  approvalView?: boolean
  approvalStatus: number
  display?: boolean
  displayCreateButton?: boolean
}

export const TablePaymentDeposit = ({
  salesOrderId,
  approvalView,
  approvalStatus,
  display = true,
  displayCreateButton = true
}: TablePaymentDepositProps) => {
  const navigate = useNavigate()
  const isApproved = approvalStatus === APPROVAL.APPROVE
  const depositCustomer = useDepositCustomerListBySo({
    params: { sales_order_id: salesOrderId }
  })
  const totalDeposit = depositCustomer.data?.reduce(
    (acc, item) => acc + Number(item.amount),
    0
  )

  return (
    <div className={`${display ? "" : "hidden"}`}>
      <BaseTable>
        <thead className="uppercase">
          <tr>
            <th>NO</th>
            <th colSpan={2}>Payment Id</th>
            <th>Date</th>
            <th>Status</th>
            <th>CC | DEBIT | CHAQUE NO.</th>
            <th>Memo</th>
            <th>Payment Deposit</th>
            <th className={approvalView ? "hidden" : ""}>Action</th>
          </tr>
        </thead>

        <tbody>
          {depositCustomer.data?.length && !depositCustomer.isLoading ? (
            depositCustomer.data.map((item, key) => {
              return (
                <tr>
                  <td className="text-center">{key + 1}</td>
                  <td className="text-center px-0">
                    <Button
                      size="xs"
                      color="ghost"
                      className={approvalView ? "hidden" : "w-full"}
                      onClick={() => {
                        navigate(
                          `/sales/transaction/payment-deposit/edit/${item.deposit_customer_id}`,
                          {
                            state: {
                              sales_order_id: item.sales_order_id
                            }
                          }
                        )
                      }}>
                      <IoSendSharp className="size-4" />
                    </Button>
                  </td>
                  <td>{item.trx_code}</td>
                  <td>{moment(item.transaction_date).format("DD/MM/YYYY")}</td>
                  <td>{APPROVAL[item.approve_status]}</td>
                  <td>{item.no_cheque}</td>
                  <td>{item.memo}</td>
                  <td className="text-right">
                    {convertNumber(Number(item.amount)).intoCurrency}
                  </td>
                  <td className={approvalView ? "hidden" : ""}>
                    <div className="flex justify-center items-center">
                      {item.approve_status !== APPROVAL.APPROVE && (
                        <ActionButton.Delete />
                      )}
                    </div>
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={9} className="text-center">
                <Loading
                  loading={depositCustomer.isLoading}
                  errorText={
                    depositCustomer.isLoading ? "" : "No data available"
                  }
                />
              </td>
            </tr>
          )}

          {depositCustomer.data?.length ? (
            <tr className="font-bold">
              <td colSpan={7} className="text-center">
                TOTAL
              </td>
              <td className="text-right">
                {convertNumber(Number(totalDeposit)).intoCurrency}
              </td>
              <td />
            </tr>
          ) : null}
        </tbody>
      </BaseTable>

      {displayCreateButton && (
        <div className="flex justify-end mt-4">
          <Link
            to={
              isApproved
                ? `/sales/transaction/payment-deposit/${salesOrderId}`
                : "#"
            }>
            <Button
              disabled={!isApproved}
              permission="ST121"
              className="uppercase"
              color="primary">
              Create Deposit
            </Button>
          </Link>
        </div>
      )}
    </div>
  )
}
