import { DateInput, Input, Select } from "components"
import moment from "moment"
import { Fragment } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { FormApproveReturnItems } from "../types"
import { APPROVAL_OPTIONS } from "../utils/vars"

export function FormSection() {
  const { control } = useFormContext<FormApproveReturnItems>()

  return (
    <Fragment>
      <section>
        <div className="gap-2 flex flex-col">
          <div>
            <Controller
              control={control}
              name="trx_code"
              render={({ field, fieldState }) => (
                <Input
                  type="text"
                  disabled
                  label="RETURN ITEMS ID#"
                  className="w-full"
                  error={fieldState.error?.message}
                  value={field.value}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="approve_date"
              render={({ field, fieldState }) => (
                <DateInput
                  placeholderText="Select Date"
                  className="w-full"
                  label="DATE"
                  error={fieldState.error?.message}
                  ref={field.ref}
                  selected={moment(field.value).toDate()}
                  onChange={(value) =>
                    field.onChange(moment(value).format("YYYY-MM-DD"))
                  }
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="employee_name"
              render={({ field, fieldState }) => (
                <Input
                  type="text"
                  disabled
                  label="EMPLOYEE"
                  className="w-full"
                  error={fieldState.error?.message}
                  defaultValue={field.value}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="job_position_name"
              render={({ field, fieldState }) => (
                <Input
                  type="text"
                  disabled
                  className="w-full"
                  label="JOB POSITION"
                  error={fieldState.error?.message}
                  value={field.value}
                />
              )}
            />
          </div>

          <div>
            <Controller
              control={control}
              name="approval_status"
              render={({ field, fieldState }) => (
                <Select
                  className="w-full"
                  options={APPROVAL_OPTIONS}
                  placeholder="Select Approve"
                  ref={field.ref}
                  label="APPROVE STATUS"
                  error={fieldState?.error?.message}
                  value={
                    APPROVAL_OPTIONS.find(
                      (item) => item.value === field.value.toString()
                    ) ?? null
                  }
                  onChange={(item) => {
                    field.onChange(item?.value)
                  }}
                />
              )}
            />
          </div>
        </div>
      </section>
    </Fragment>
  )
}
