import * as yup from "yup"

export type FormApproveReturnItems = yup.InferType<
  typeof formApproveReturnItemsSchema
>

export const formApproveReturnItemsSchema = yup.object().shape({
  return_id: yup.number().label("Return Items Id").optional(),
  trx_code: yup.string().label("Return Items").optional(),
  approve_date: yup.string().label("Approve Date").required(),
  employee_id: yup.number().label("Employee Name").required(),
  employee_name: yup.string().label("Employee Name").optional(),
  job_position_name: yup.string().label("Job Position").optional(),
  approval_status: yup.number().label("Approve Status").required()
})
