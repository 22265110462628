// React
import { Fragment, useContext } from "react"

// Components
import { ActionButton, BaseTable, Button, Loading } from "components"

// Contexts
import { PurchaseOrderContext } from "../../../contexts"

// Form
import { useFormContext } from "react-hook-form"

// Icons
import { IoSendSharp } from "react-icons/io5"

// Third-Party Libraries
import moment from "moment"
import { Link, useParams } from "react-router-dom"

// Utils
import { convertNumber } from "utils"
import { type FormType, useDepositVendorList } from "../../../utils"

export function Payment() {
  // Hooks
  const { disabled } = useContext(PurchaseOrderContext)
  const { id } = useParams()
  const { data, isLoading } = useDepositVendorList(id!)

  // Form
  const { getValues } = useFormContext<FormType>()

  // Vars
  const isApproved: boolean = getValues("approval_status") === 4

  return (
    <Fragment>
      <BaseTable>
        <thead>
          <tr>
            <th></th>
            <th>ACCOUNT ID#</th>
            <th>DATE</th>
            <th>STATUS</th>
            <th>MEMO</th>
            <th>DEPOSIT</th>
            <th>USED</th>
            <th>BALANCE</th>
            <th>ACTION</th>
          </tr>
        </thead>

        <tbody>
          {isLoading || !data ? (
            <tr>
              <td colSpan={9}>
                <Loading loading={isLoading} />
              </td>
            </tr>
          ) : data.map((item, key) => {
            // Vars
            const balance: number = item.amount - item.deposit_used

            return (
              <tr key={key}>
                <td>
                  {!disabled && <IoSendSharp />}
                </td>

                <td>{item.trx_code}</td>
                <td>{moment(item.transaction_date).format("DD/MM/YYY")}</td>
                <td>{item.approve_status}</td>
                <td>{item.memo}</td>
                <td className="text-right">{convertNumber(item.amount).intoCurrency}</td>
                <td className="text-right">{convertNumber(item.deposit_used).intoCurrency}</td>
                <td className="text-right">{convertNumber(balance).intoCurrency}</td>
                <td className="text-center">
                  {!disabled && <ActionButton.Delete />}
                </td>
              </tr>
            )
          })}
        </tbody>
      </BaseTable>

      <section className="flex justify-end">
        {!disabled && (
          <Link to={isApproved ? `/purchase/transaction/deposit-vendor/${id}` : "#"}>
            <Button
              className="w-fit"
              color="primary"
              disabled={!isApproved}
            >
              CREATE DEPOSIT
            </Button>
          </Link>
        )}
      </section>
    </Fragment>
  )
}