// React
import { useContext } from "react"

// Components
import { Card, CommandButton, Loading } from "components"
import { InputSection } from "./components"

// Contexts
import { DataContext, ParamsContext } from "./contexts"

// Form
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Third-Party Libraries
import moment from "moment"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"

// Utils
import { useApi } from "utils"
import { type FormType, useDetail, validationSchema } from "./utils"

export default function RejectItem(): JSX.Element {
  // Hooks
  const { purchase_order_id, bill_id } = useParams()
  const { data, isLoading } = useDetail(purchase_order_id!, bill_id!)

  if (isLoading || !data) {
    return (
      <Loading
        errorText="No data available"
        loading={isLoading}
      />
    )
  }

  return (
    <DataContext.Provider value={data}>
      <FormSection />
    </DataContext.Provider>
  )
}

function FormSection(): JSX.Element {
  // Hooks
  const api = useApi()
  const navigate = useNavigate()
  const { data } = useContext(DataContext)
  const { bill_id } = useParams()

  // Form
  const defaultValues: FormType = {
    trx_code: "",
    transaction_date: moment().format("YYYY-MM-DD"),
    memo: "",
    referensi: "",
    prorejectdetail: []
  }
  const methods = useForm<FormType>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })
  const onSubmit = (value: FormType) => {
    // Vars
    const finalValue = {
      ...value,
      bill_id,
      payment_term_vendor_id: data.payment_term_vendor_id,
      vendor_id: data.vendor_id,
      location_id: data.location_id,
      address: data.address,
      prorejectdetail: value.prorejectdetail.map(item => {
        // Vars
        const quantity_reject = item.quantity_reject
        const quantity_unit: number = quantity_reject * item.quantity_rate
        const subtotal: number = quantity_reject * item.price_reject
        const total: number = subtotal - (item.discount / 100 * subtotal)
        const total_tax_paid: number = item.tax_rate / 100 * total

        return {
          quantity_reject,
          quantity_unit,
          total,
          total_tax_paid,
          product_id: item.product_id,
          price_reject: item.price_reject,
          discount: item.discount,
          tax_id: item.tax_id,
          tax_rate: item.tax_rate
        }
      })
    }

    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/proreject/add", finalValue),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.detail?.message ?? err.response.data.message
        }
      ).then(() => {
        navigate(-1)
      }).catch(() => {}).finally(resolve)
    })
  }

  return (
    <FormProvider {...methods}>
      <ParamsContext.Provider value={{}}>
        <InputSection
          actionbutton={(
            <Card>
              <Card.Body className="flex flex-col gap-5">
                <section className="flex flex-wrap justify-between gap-3">
                  <section className="flex flex-wrap gap-3">
                    <CommandButton actiontype="help" />
                    <CommandButton actiontype="print" />
                    <CommandButton actiontype="email" />
                    <CommandButton actiontype="export" />
                    <CommandButton actiontype="journal" />
                    <CommandButton actiontype="attachment" />
                  </section>

                  <section className="flex flex-wrap gap-3">
                    <CommandButton actiontype="delete" />

                    <CommandButton
                      actiontype="save"
                      loading={methods.formState.isSubmitting ? "true" : undefined}
                      onClick={methods.handleSubmit(onSubmit)}
                    />
                  </section>
                </section>
              </Card.Body>
            </Card>
          )}
        />
      </ParamsContext.Provider>
    </FormProvider>
  )
}