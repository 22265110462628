import { useEffect, useState } from "react"
import type { SelectOptionType, SuccessFetch } from "../../../../../../types"
import { useApi } from "../../../../../../utils"

export function useEmployee() {
  const api = useApi()
  const [dataEmployee, setData] = useState<SelectOptionType[]>([])
  const [isLoadingEmployee, setLoading] = useState(true)

  useEffect(() => {
    api.get("/employee/")
    .then((res: SuccessFetch<{
      payload: {
        employee_id: number
        employee_name: string
      }[]
    }>) => { setData(res.data.payload.map(item => { return { label: item.employee_name, value: item.employee_id.toString() } })) })
    .catch(() => setData([]) )
    .finally(() => setLoading(false))
  
    return () => {
      setData([])
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { dataEmployee, isLoadingEmployee }
}