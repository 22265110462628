// EXCEPTIONS
// #1: ignore type error to check field name is available on the form type

// React
import { useContext } from "react"

// Components
import { Select, Textarea } from "components"

// Contexts
import { DisabledContext } from "../contexts"

// Form
import { Controller, useFormContext } from "react-hook-form"

// Utils
import { useLocation } from "utils"

export function Deliver(params: {
  disabled?: boolean
  deliver_name: string
  detail_name: string
}) {
  // Hooks
  const disabled = useContext(DisabledContext)
  const { data, isLoading } = useLocation()

  // Form
  const { control, setValue, clearErrors } = useFormContext()

  return (
    <section>
      <Controller
        name={params.deliver_name}
        control={control}
        render={({ field, fieldState }) => (
          <Select
            label="DELIVER TO"
            placeholder="Select Deliver"
            error={fieldState.error?.message}
            isDisabled={params.disabled || disabled}
            isLoading={isLoading}
            options={data}
            ref={field.ref}
            value={data.find(item => item.value === field.value) ?? null}
            onChange={e => {
              if (e) {
                field.onChange(e.value)
                // @ts-expect-error [EXCEPTION #1]
                setValue(params.detail_name, e.address)
                clearErrors(params.detail_name)
              }
            }}
          />
        )}
      />

      <Controller
        control={control}
        name={params.detail_name}
        render={({ field, fieldState }) => (
          <Textarea
            disabled={params.disabled}
            error={fieldState.error?.message}
            ref={field.ref}
            value={field.value}
          />
        )}
      />
    </section>
  )
}