import * as yup from "yup"
import { formSalesOrderSchema } from "pages/Sales/Transaction/CustomerOrder/types"
import { formInvoiceDetailSchema } from "./FormInvoiceDetail"

export type FormInvoiceType = yup.InferType<typeof formInvoiceSchema>

export const formInvoiceSchema = formSalesOrderSchema
  .omit(["sodetail", "approval_status_text"])
  .shape({
    delivery_status: yup.number().label("Delivery Status").required(),
    delivery_no: yup.number().label("Delivered").required(),
    link: yup.string().label("Link").required(),
    sodetail: yup.array().of(formInvoiceDetailSchema),
    invoice_status_text: yup.string().label("Invoice Status"),
    invoice_detail: yup
      .array()
      .of(formInvoiceDetailSchema)
      .label("Invoice detail")
      .min(1)
      .required()
  })
