// Pages
import All from "pages/Card/Register/All"
import Find from "pages/Accounts/Find"
import Journal from "pages/Finance/Journal"
import { CreatePage } from "pages/Card/Transaction/CreateNewVendor/components"
import { CreatePage as CreateCustomer } from "pages/Card/Transaction/CreateNewCustomer/components"
import { CreatePage as CreateEmployee } from "pages/Card/Transaction/CreateNewEmployee/components"
import { CreatePageTwo as CreateEmployeeTwo } from "pages/Card/Transaction/CreateNewEmployee/components"
import { DetailPage, UpdatePage } from "pages/Card/Transaction/CreateNewVendor/components/ChildItem/components/Item/components"
import { DetailPage as DetailCustomer, UpdatePage as UpdateCustomer } from "pages/Card/Transaction/CreateNewCustomer/components/ChildItem/components/Item/components"
import { DetailPage as DetailEmployee, UpdatePage as UpdateEmployee } from "pages/Card/Transaction/CreateNewEmployee/components/ChildItem/components/Item/components"

// Types
import type { RouteType } from "types"

export const cardsRoutes: RouteType[] = [
  {
    path: "/cards/register/all",
    element: <All />,
    permission: "CR" 
  },
  {
    path: "/cards/transaction/create-new-vendor",
    element: <CreatePage />,
    permission: "CT04"
  },
  {
    path: "/cards/transaction/create-new-vendor/create",
    element: <CreatePage />,
    permission: "CT041"
  },
  {
    path: "/cards/transaction/create-new-vendor/update",
    element: <UpdatePage />,
    permission: "CR033"
  },
  {
    path: "/cards/transaction/create-new-vendor/detail",
    element: <DetailPage />,
    permission: "CR032"
  },
  {
    path: "/cards/transaction/create-new-customer",
    element: <CreateCustomer />,
    permission: "CT03"
  },
  {
    path: "/cards/transaction/create-new-customer/create",
    element: <CreateCustomer />,
    permission: "CT031"
  },
  {
    path: "/cards/transaction/create-new-customer/update",
    element: <UpdateCustomer />,
    permission: "CR023"
  },
  {
    path: "/cards/transaction/create-new-customer/detail",
    element: <DetailCustomer />,
    permission: "CR022"
  },

  {
    path: "/cards/transaction/create-new-employee",
    element: <CreateEmployee />,
    permission: "CT02"
  },
  {
    path: "/cards/transaction/create-new-employees",
    element: <CreateEmployeeTwo />,
    permission: "CT02"
  },
  {
    path: "/cards/transaction/create-new-employee/create",
    element: <CreateEmployee />,
    permission: "CT021"
  },
  {
    path: "/cards/transaction/create-new-employee/update",
    element: <UpdateEmployee />,
    permission: "CR013"
  },
  {
    path: "/cards/transaction/create-new-employee/detail",
    element: <DetailEmployee />,
    permission: "CR012"
  },
  {
    path: "/cards/find",
    element: <Find />,
    // permission: "CF"
    permission: "CT" // BELUM
  },
  {
    path: "/cards/journal",
    element: <Journal />,
    // permission: "CJ"
    permission: "CT" // BELUM
  },
  {
    path: "/cards/transaction",
    element: <div />,
    permission: "CT" // BELUM
  },
  {
    path: "/cards/transaction/card-register",
    element: <div />,
    permission: "CT" // BELUM
  },
  {
    path: "/cards/transaction/transaction-journal",
    element: <div />,
    permission: "CT" // BELUM
  },
  {
    path: "/cards/transaction/create-new-business",
    element: <div />,
    permission: "CT" // BELUM
  },
  {
    path: "/cards/transaction/dashboard",
    element: <div />,
    permission: "CT" // BELUM
  },
  {
    path: "/cards/report/card-list-summary",
    element: <div />,
    permission: "CT" // BELUM
  },
  {
    path: "/cards/report/card-list-detail",
    element: <div />,
    permission: "CT" // BELUM
  },
  {
    path: "/cards/report/card-transaction",
    element: <div />,
    permission: "CT" // BELUM
  },
  {
    path: "/cards/report/overdue-contact",
    element: <div />,
    permission: "CT" // BELUM
  },
  {
    path: "/cards/report/dashboard",
    element: <div />,
    permission: "CT" // BELUM
  },
]