// React
import { Fragment } from "react"

// Components
import { Input, NumberInput, Select, Textarea } from "../../../../../components"

// Form
import { Controller, useFormContext } from "react-hook-form"

// Types
import type { FormType } from "../types"

// Utils
import { useCoa, useType } from "../utils"

export function FormSection(props: {
  isDisabled?: boolean
}) {
  // Form
  const { control } = useFormContext<FormType>()

  return (
    <section className="flex flex-col gap-3">
      <Controller
        control={control}
        name="tax_code"
        render={({ field, fieldState }) => (
          <Input
            {...field}
            ref={null}
            label="CODE"
            placeholder="Input Code"
            disabled={props.isDisabled}
            error={fieldState.error?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="tax_name"
        render={({ field, fieldState }) => (
          <Input
            {...field}
            ref={null}
            label="NAME"
            placeholder="Input Name"
            disabled={props.isDisabled}
            error={fieldState.error?.message}
          />
        )}
      />

      <Type isDisabled={props.isDisabled} />

      <Controller
        control={control}
        name="rate"
        render={({ field, fieldState }) => (
          <NumberInput
            label="RATE (%)"
            placeholder="Input Rate (%)"
            disabled={props.isDisabled}
            error={fieldState.error?.message}
            value={field.value}
            onValueChange={value => field.onChange(value.floatValue ?? 0)}
          />
        )}
      />

      <Coa isDisabled={props.isDisabled} />

      <Controller
        control={control}
        name="note"
        render={({ field, fieldState }) => (
          <Textarea
            {...field}
            ref={null}
            label="NOTE"
            placeholder="Input Note"
            disabled={props.isDisabled}
            error={fieldState.error?.message}
          />
        )}
      />
    </section>
  )
}

function Type(props: { isDisabled?: boolean }) {
  // Hooks
  const { data, isLoading } = useType()

  // Form
  const { control } = useFormContext<FormType>()

  return (
    <Controller
      control={control}
      name="type_tax_id"
      render={({ field, fieldState }) => (
        <Select
          label="TYPE"
          placeholder="Select Item"
          error={fieldState.error?.message}
          isDisabled={props.isDisabled}
          isLoading={isLoading}
          options={data}
          value={data.find(item => item.value === field.value)}
          onChange={e => field.onChange(e?.value)}
        />
      )}
    />
  )
}

function Coa(props: { isDisabled?: boolean }) {
  // Hooks
  const { data, isLoading } = useCoa()

  // Form
  const { control } = useFormContext<FormType>()

  return (
    <Fragment>
      <Controller
        control={control}
        name="coa_collect_id"
        render={({ field, fieldState }) => (
          <Select
            label="COA COLLECT"
            placeholder="Select Item"
            error={fieldState.error?.message}
            isDisabled={props.isDisabled}
            isLoading={isLoading}
            options={data}
            value={data.find(item => item.value === field.value)}
            onChange={e => field.onChange(e?.value)}
          />
        )}
      />

      <Controller
        control={control}
        name="coa_paid_id"
        render={({ field, fieldState }) => (
          <Select
            label="COA PAID"
            placeholder="Select Item"
            error={fieldState.error?.message}
            isDisabled={props.isDisabled}
            isLoading={isLoading}
            options={data}
            value={data.find(item => item.value === field.value)}
            onChange={e => field.onChange(e?.value)}
          />
        )}
      />
    </Fragment>
  )
}