/* eslint-disable react-hooks/exhaustive-deps */
import { HeaderMenu, NumberInput } from "components"
import { useEffect } from "react"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { useBalance } from "utils"
import { DropdownAccount } from "../../PaymentDeposit/components"
import { PaymentInvoiceType } from "../types"
import { CodePaymentInvoice } from "./CodePaymentInvoice"
import { CommandButtons } from "./CommandButtons"
import { FormPaymentInvoice } from "./FormPaymentInvoice"
import { TableJournalEntry } from "./Tables/JournalEntry"

export type FormSectionProps = {
  isEdit?: boolean
  onSubmit: () => void
}

export function FormSection({ isEdit = false, onSubmit }: FormSectionProps) {
  const methods = useFormContext<PaymentInvoiceType>()
  const { refetch } = useBalance("/spendmoney/balance")
  const [coa_id, transaction_date] = useWatch({
    control: methods.control,
    name: ["coa_id", "transaction_date"]
  })

  useEffect(() => {
    if (coa_id && transaction_date) {
      ;(async () => {
        const coaBalance = await refetch(coa_id.toString(), transaction_date)

        methods.clearErrors(["payment_amount", "deposit_amount"])
        methods.setValue("coa_balance", coaBalance ?? 0)
      })()
    }
  }, [coa_id, transaction_date])

  return (
    <section className="container my-5 flex flex-col gap-5">
      <section>
        <HeaderMenu title="DATA ENTRY | PAYMENT INVOICE">
          <CodePaymentInvoice isEdit={isEdit} />
        </HeaderMenu>

        <section className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
          <section className="w-full">
            <Controller
              control={methods.control}
              name="coa_id"
              render={({ field, fieldState }) => (
                <DropdownAccount
                  value={field.value.toString()}
                  displayBalance={false}
                  label="PAYMENT FROM ACCOUNT"
                  error={fieldState.error?.message}
                  onChange={async (data) => {
                    field.onChange(data?.value)
                  }}
                />
              )}
            />
          </section>

          <section className="lg:justify-self-end self-end mb-3">
            <Controller
              control={methods.control}
              name="coa_balance"
              render={({ field }) => (
                <NumberInput
                  disabled
                  textRight
                  label="BALANCE"
                  value={field.value}
                />
              )}
            />
          </section>
        </section>

        <section className="mt-3">
          <FormPaymentInvoice isEdit={isEdit} />
        </section>

        <section className="mt-6">
          <TableJournalEntry />
        </section>
      </section>

      <CommandButtons showDeleteButton={isEdit} onSave={onSubmit} />
    </section>
  )
}
