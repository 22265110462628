// React
import { Fragment, PropsWithChildren } from "react"

// Components
import { ActionButton } from "./ActionButton"

// Third-Party Libraries
import { Dialog, Transition } from "@headlessui/react"

function Modal(props: PropsWithChildren<{
  closeModal?: () => void
  isOpen: boolean
  isdarktitle?: boolean
  size?: "full" | "xl" | "lg" | "md" | "6xl"
  title: string
}>): JSX.Element {
  return (
    <Transition appear show={props.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          if (props.closeModal) {
            props.closeModal()
          }
        }}>
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center bg-gray-500 bg-opacity-75">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="flex flex-col w-full transform overflow-hidden rounded-2xl bg-white dark:bg-slate-600 text-left align-middle shadow-xl transition-all max-w-[80dvw]">
                <div className={`flex justify-between items-center p-6 ${props.isdarktitle && "bg-black"}`}>
                  <Dialog.Title
                    as="h3"
                    className={`text-lg font-bold leading-6 ${props.isdarktitle && "text-white"}`}
                  >
                    {props.title}
                  </Dialog.Title>

                  <ActionButton.Close
                    className={`${props.isdarktitle && "text-white"}`}
                    onClick={props.closeModal}
                  />
                </div>

                <hr className="m-0" />

                {props.children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

function Body(props: PropsWithChildren<{
  className?: string
  hintText?: string
}>): JSX.Element {
  return (
    <div className={`h-[65dvh] ${props.hintText && "grid lg:grid-cols-5 divide-x"} ${props.className}`}>
      <div className={`h-full px-6 p-3  overflow-auto ${props.hintText && "lg:col-span-2"}`}>
        {props.children}
      </div>

      {props.hintText && (
        <div className="px-6 p-3 overflow-auto lg:col-span-3 lg:block hidden">
          {props.hintText}
        </div>
      )}
    </div>
  )
}

function Footer(props: PropsWithChildren<{
  className?: string
}>) {
  return (
    <Fragment>
      <div className="divider m-0" />
      <div className={`p-6 ${props.className ?? "text-right"}`}>{props.children}</div>
    </Fragment>
  )
}

Modal.Body = Body
Modal.Footer = Footer

export { Modal }
