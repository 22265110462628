import { BaseTable, Card, Loading } from "components"
import { UseFilter } from "pages/Finance/Journal/utils/hooks"
import { RegisterDateType } from "../types"
import { Item } from "./Item"

type TableRegisterProps = {
  data: RegisterDateType[]
  isLoading?: boolean
  filter: UseFilter
  handleCheckData: (index: number) => void
  fetchList: () => void
  permission: string
}

export const TableRegister = ({
  data,
  isLoading,
  filter,
  handleCheckData,
  fetchList,
  permission
}: TableRegisterProps) => {
  return (
    <Card>
      <Card.Body className="max-h-96">
        <BaseTable className="border-transparent">
          <thead>
            <tr className="uppercase">
              <th>No</th>
              <th>Trade Id</th>
              <th>Date</th>
              <th>Trader</th>
              <th>Ref No</th>
              <th>MRK</th>
              <th>Memo</th>
              <th>Location</th>
              <th>Debit</th>
              <th>Credit</th>
              <th>Check</th>
              <th>Actions</th>
            </tr>
          </thead>

          <tbody>
            {!data.length || isLoading ? (
              <tr>
                <td colSpan={10} className="text-center">
                  <Loading
                    loading={isLoading}
                    errorText={isLoading ? "" : "No data available"}
                  />
                </td>
              </tr>
            ) : (
              data.map((item: any, key: any) => (
                <Item
                  filter={filter}
                  item={item}
                  index={key}
                  fetchList={fetchList}
                  onChangeCheckbox={handleCheckData}
                  permission={permission}
                />
              ))
            )}
          </tbody>
        </BaseTable>
      </Card.Body>
    </Card>
  )
}
