import { Fragment } from "react"
import { ActionButton, BaseTable, Card, CommandButton, DateInput, Loading, PermissionLink, Select } from "components"
import { DeletePurchaseOrderModal } from "../../../components"
import { IoSendSharp } from "react-icons/io5"
import type { DetailDeleteType } from "../../../types"
import moment from "moment"
import { convertNumber, useToggle } from "utils"
import { default_option, delivery_option, usePurchaseList } from "../../utils"

export function ByDateRange(): JSX.Element {
  const { data, filter, isLoading, refetch, setFilter } = usePurchaseList({
    Approval: "0",
    DateEnd: moment().endOf('month').format('YYYY-MM-DD'),
    DateFrom: moment().startOf('month').format('YYYY-MM-DD'),
    Delivered: "0"
  })
  const totalPayment = data.reduce((total, item) => total + item.total_payment, 0);
  const totalBalance = data.reduce((total, item) => total + item.balance_owing, 0);

  return (
    <section className="container flex flex-col gap-3">
      <section className="mt-2">
        <Card>
          <Card.Body>
            <section className="lg:flex overflow-x-auto">
              <div className='flex items-center w-[284px] lg:w-[240px]'>
                <div className="label-text pb-1 font-bold pe-2 lg:w-[145px] w-[350px] uppercase">Date From</div>
                <DateInput
                  className="!w-[150px]"
                  selected={moment(filter.DateFrom).toDate()}
                  onChange={(date) => setFilter({ ...filter, DateFrom: moment(date).format("YYYY-MM-DD") })}
                />
              </div>
              <div className='flex items-center w-[284px] lg:w-[230px] mt-2 lg:mt-0'>
                <div className="lg:mt-0 lg:px-2 pb-1 label-text font-bold lg:w-[110px] !w-[550px] uppercase">Date To</div>
                <DateInput
                  className="!w-[150px]"
                  selected={moment(filter.DateEnd).toDate()}
                  onChange={(date) => setFilter({ ...filter, DateEnd: moment(date).format("YYYY-MM-DD") })}
                />
              </div>
              <div className='flex items-center ml-1 mt-2 lg:mt-0'>
                <div className="lg:mt-0 lg:px-2 pb-1 label-text font-bold w-[142px] lg:w-full">APPROVAL</div>
                <Select
                  className="w-[150px]"
                  value={default_option.find(item => item.value === filter.Approval)}
                  options={default_option}
                  onChange={res => { if (res) { setFilter({ ...filter, Approval: res.value })}}}
                />
              </div>
              <div className='flex items-center ml-1 mt-2 lg:mt-0'>
                <div className="lg:mt-0 lg:px-2 pb-1 label-text font-bold w-[142px] lg:w-full">DELIVERED</div>
                <Select
                  className="w-[150px]"
                  value={delivery_option.find(item => item.value === filter.Delivered)}
                  options={delivery_option}
                  onChange={res => { if (res) { setFilter({ ...filter, Delivered: res.value })}}}
                />
              </div>
            </section>
          </Card.Body>
        </Card>
      </section>

      <section>
        <Card>
          <Card.Body>
            <div className="overflow-x-auto">
              <BaseTable>
                <thead>
                  <tr>
                    <th rowSpan={2}>NO</th>
                    <th colSpan={2} rowSpan={2}>PO ID#</th>
                    <th rowSpan={2}>DATE</th>
                    <th rowSpan={2}>VENDOR</th>
                    <th colSpan={2}>STATUS</th>
                    <th rowSpan={2}>QTY<br/>ORDER</th>
                    <th rowSpan={2}>QTY<br/>DELIVERED</th>
                    <th rowSpan={2}>QTY<br/>BACKORDER</th>
                    <th rowSpan={2}>TOTAL<br/>PURCHASE</th>
                    <th rowSpan={2}>TOTAL<br/>PAYMENT</th>
                    <th rowSpan={2}>BALANCE<br/>OWING</th>
                    <th rowSpan={2}>ACTION</th>
                  </tr>
                  <tr>
                    <th>APPROVAL</th>
                    <th>DELIVERED</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading || data.length === 0 ? (
                    <tr>
                      <td colSpan={13}>
                        <Loading loading={isLoading} errorText="No data available" />
                      </td>
                    </tr>
                  ) : data.map((item, key) => (
                    <tr key={key}> 
                      <td>{key + 1}</td>

                      <td>
                        <PermissionLink permission={"PR01"} to={`/purchase/transaction/purchase-order/${item.purchase_order_id}`}>
                          <IoSendSharp/>
                        </PermissionLink>
                      </td>

                      <td>{item.trx_code}</td>
                      <td>{moment(item.transaction_date).format("DD/MM/YYYY")}</td>
                      <td>{item.vendor_name}</td>
                      <td>{item.approval}</td>
                      <td>{item.delivery}</td>
                      <td className="text-center">{item.qty_order ? convertNumber(item.qty_order).intoNormalAmount : "-"}</td>
                      <td className="text-center">{item.qty_delivered ? convertNumber(item.qty_delivered).intoNormalAmount : "-"}</td>
                      <td className="text-center">{item.qty_back_order ? convertNumber(item.qty_back_order).intoNormalAmount : "-"}</td>
                      <td className="text-right">{item.total_purchase ? convertNumber(item.total_purchase).intoCurrency : "-"}</td>
                      <td className="text-right">{item.total_payment ? convertNumber(item.total_payment).intoCurrency : "-"}</td>
                      <td className="text-right">{item.balance_owing ? convertNumber(item.balance_owing).intoCurrency : "-"}</td>
                      <td>
                        <section className="flex justify-center">
                          {item.approval_status === 1 && (
                            <Delete
                              item={{
                                purchase_order_id: item.purchase_order_id.toString(),
                                transaction_date: item.transaction_date,
                                trx_code: item.trx_code,
                                vendor_name: item.vendor_name,
                              }}
                              onSuccess={refetch}
                            />
                          )}
                        </section>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </BaseTable>
            </div>
          </Card.Body>
        </Card>
      </section>

      <Card>
        <Card.Body className="flex sm:flex-row flex-col justify-between sm:items-center">
          <section className="flex flex-wrap gap-3">
            <CommandButton actiontype="help" />
            <CommandButton actiontype="print" />
            <CommandButton actiontype="email" />
            <CommandButton actiontype="export" />
          </section>

          <section className="flex flex-col">
            <div className="label-text font-bold flex justify-end text-right">
              <div>
                <div>SUMMARY PAYMENT</div>
                <div>SUMMARY BALANCE</div>
              </div>
              <div className="mx-2">
                <div>:</div>
                <div>:</div>
              </div>
              <div>
                <div>{totalPayment ? convertNumber(totalPayment)?.intoCurrency : "0"}</div>
                <div>{totalBalance ? convertNumber(totalBalance)?.intoCurrency : "0"}</div>
              </div>
            </div>
          </section>
        </Card.Body>
      </Card>
    </section>
  )
}

function Delete(params: { item: DetailDeleteType; onSuccess: () => void }) {
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <ActionButton.Delete
        permission={"PR014"}
        className="text-error"
        onClick={toggle}
      />

      {isActive && (
        <DeletePurchaseOrderModal
          onSuccess={params.onSuccess}
          toggle={toggle}
          item={{
            purchase_order_id: params.item.purchase_order_id.toString(),
            transaction_date: params.item.transaction_date,
            trx_code: params.item.trx_code,
            vendor_name: params.item.vendor_name
          }}
        />
      )}
    </Fragment>
  )
}