import { FormInvoiceType, SubmitType } from "../../types"

export const submitMap = (data: FormInvoiceType): SubmitType => {
  return {
    sales_order_id: data?.sales_order_id!,
    trx_code: data.trx_code ?? "",
    customer_id: data.customer_id,
    location_id: data.location_id,
    address: data.shipped,
    memo: data.memo,
    transaction_date: data.transaction_date,
    referensi: data.referensi,
    delivery_no: data.delivery_no,
    freight: data?.freight ?? 0,
    promise_date: data?.promize_date,
    invoice_detail:
      data?.invoice_detail?.map(
        ({
          product_id,
          quantity_invoice,
          total,
          price_measure,
          discount,
          tax_id,
          mr_unit_id,
          sales_order_detail_id,
          invoice_detail_id,
          invoice_id
        }) => ({
          invoice_id: invoice_id,
          invoice_detail_id: invoice_detail_id,
          sales_order_detail_id: sales_order_detail_id,
          quantity_unit: mr_unit_id,
          quantity_invoice: quantity_invoice ?? 0,
          product_id: product_id,
          price_invoice: price_measure,
          discount: discount,
          total: total,
          tax_id: tax_id
        })
      ) ?? [],
    reminder: data.reminder
      ? {
        card_group: data.reminder.card_group,
        card_id: data.reminder.card_id,
        transaction_date: data.reminder.transaction_date,
        promise_date: data.reminder.promise_date,
        memo: data.reminder.memo
      }
      : null
  }
}
