import { Button, Loading, Modal } from 'components';
import { RegisterDateType } from 'pages/Sales/Register/types';
import { Fragment, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useApi, useToggle } from 'utils';

type ModalDeleteProps = {
  data: Pick<RegisterDateType, "approval_status" | "sales_order_id" | "transaction_date" | "trx_code" | "customer_id" | "customer_name">;
  modalState: ReturnType<typeof useToggle>;
  onSuccess?: () => void;
};

export const ModalDelete = ({
  data,
  modalState,
  onSuccess,
}: ModalDeleteProps) => {
  const [loadingData, setLoadingData] = useState(false);
  const api = useApi();

  const handleDeleteData = async () => {
    setLoadingData(true);
    await toast
      .promise(api.delete(`salesorder/delete/${data?.sales_order_id}`), {
        loading: 'Loading...',
        success: (res) => res?.data?.message,
        error: (err) => err.response?.data?.detail?.message,
      })
      .then(() => {
        modalState.setActive(false);
        onSuccess?.();
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  return (
    <Modal
      isOpen={modalState.isActive}
      title=""
      size="xl"
      closeModal={modalState.toggle}
    >
      <Modal.Body>
        {loadingData ? (
          <Loading loading />
        ) : (
          <Fragment>
            <section className="mb-3 border-b pb-3">
              <div className="text-xl font-bold">Delete Sales Order</div>
              <small className="text-lg font-medium text-red-400">
                Are you sure want to delete this data?
              </small>
            </section>
          </Fragment>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="button"
          color="error"
          disabled={loadingData}
          onClick={handleDeleteData}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
