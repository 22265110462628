import { Fragment } from "react"
import { useSearchParams } from "react-router-dom"
import { TabNavRegister } from "./TabNavRegister"
import { TabRegisterPath } from "./types"
import { ItemsGroup } from "./ItemGroup"
import { ItemsTransfer } from "./ItemTransfer"
import { ItemsAdjustment } from "./ItemAdjustment"
import { ItemsCount } from "./ItemCount"
import { ItemsBuild } from "./ItemBuild"
import { HeaderMenu } from "components"

type TabsSearchParams = {
  tabs: TabRegisterPath
}

export default function InventoriRegister() {
  const [searchParams, setSearchParams] = useSearchParams({ tabs: "items-group" } as TabsSearchParams)
  const activeTab = searchParams.get("tabs")
  const setActiveTab = (path: string) => setSearchParams({ tabs: path })

  return (
    <Fragment>
      <section className="container my-5">
        <HeaderMenu title="INVENTORY REGISTER"/>
        <TabNavRegister activeTab={activeTab!} setActiveTab={setActiveTab} />
        {activeTab === "items-group" && <ItemsGroup />}
        {activeTab === "items-transfer" && <ItemsTransfer />}
        {activeTab === "items-adjustment" && <ItemsAdjustment />}
        {activeTab === "items-count" && <ItemsCount />}
        {activeTab === "items-build" && <ItemsBuild />}
      </section>
    </Fragment>
  )
}
