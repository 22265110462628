/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi } from "utils"

type UseDepositCustomerListBySoProps = {
  params: {
    sales_order_id?: number
  }
}

type Response = {
  payload: {
    deposit_customer_id: number
    sales_order_id: number
    coa_id: number
    customer_id: number
    location_id: number
    project_id: number
    trx_code: string
    transaction_date: string
    amount: string
    deposit_used: string
    memo: string
    no_cheque: string
    approve_status: number
    approve_date: string | null
    approve_by: string | null
  }[]
}

export function useDepositCustomerListBySo({
  params
}: UseDepositCustomerListBySoProps) {
  const api = useApi()
  const [data, setData] = useState<Response["payload"]>([])
  const [isLoading, setLoading] = useState(false)

  const fetchData = () => {
    setLoading(true)

    api
      .get("depositcustomer/deposit_customer_byso", { params })
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload ?? []
        setData(data)
      })
      .catch(() => setData([]))
      .finally(() => setLoading(false))
  }

  const refetch = () => fetchData()

  useEffect(() => {
    fetchData()

    return () => {
      setData([])
      setLoading(false)
    }
  }, [params.sales_order_id])

  return {
    data,
    isLoading,
    refetch,
    dropdown: data.map((item) => ({ value: item, label: item }))
  }
}
