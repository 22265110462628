// React
import { Fragment } from "react"

export function PaymentApprovedSection(params: {
  payment_status?: string
  approved_by?: string
}) {
  return (
    <Fragment>
      <tr>
        <td className="w-[1px] whitespace-nowrap">APPROVE STATUS</td>
        <td>{params.payment_status ?? "-"}</td>
      </tr>
      <tr>
        <td className="w-[1px] whitespace-nowrap">APPROVED BY</td>
        <td>{params.approved_by ?? "-"}</td>
      </tr>
    </Fragment>
  )
}