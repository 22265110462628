import { BaseTable, Button, Loading } from "components"
import moment from "moment"
import { APPROVAL } from "pages/Sales/Register/utils/vars"
import { IoSendSharp } from "react-icons/io5"
import { useNavigate } from "react-router-dom"
import { convertNumber } from "utils"
import { usePaymentInvoiceListBySo } from "../../../utils/hooks"

type TablePaymentInvoiceProps = {
  salesOrderId?: number
  approvalView?: boolean
  approvalStatus: number
  display?: boolean
  displayCreateButton?: boolean
}

export const TablePaymentInvoice = ({
  salesOrderId,
  approvalView,
  display = true
}: TablePaymentInvoiceProps) => {
  const navigate = useNavigate()
  const paymentInvoice = usePaymentInvoiceListBySo({
    params: { sales_order_id: salesOrderId }
  })
  const calculatePaymentInvoice = paymentInvoice?.data?.reduce(
    (acc, curr) => {
      const fTotal = parseFloat(curr.total)
      const fDepo = parseFloat(curr.depo)
      const fPayment = parseFloat(curr.payment)

      const balance = fTotal - (fDepo + fPayment)

      return {
        total: acc.total + fTotal,
        depo: acc.depo + fDepo,
        payment: acc.payment + fPayment,
        balance: acc.balance + balance
      }
    },

    {
      total: 0,
      depo: 0,
      payment: 0,
      balance: 0
    }
  )

  return (
    <div className={`${display ? "" : "hidden"}`}>
      <BaseTable>
        <thead className="uppercase">
          <tr>
            <th rowSpan={2}>NO</th>
            <th rowSpan={2} colSpan={2}>
              Invoice Id
            </th>
            <th rowSpan={2}>Status</th>
            <th rowSpan={2}>Date</th>
            <th rowSpan={2}>Delivery</th>
            <th rowSpan={2}>Memo</th>
            <th rowSpan={2}>Subtotal</th>
            <th colSpan={2}>Payment</th>
            <th rowSpan={2}>Balance</th>
            <th className={approvalView ? "hidden" : ""} rowSpan={2}>Action</th>
          </tr>
          <tr>
            <td>Deposit</td>
            <td>Payment</td>
          </tr>
        </thead>

        <tbody>
          {paymentInvoice.data?.length && !paymentInvoice.isLoading ? (
            paymentInvoice.data.map((item, key) => {
              const balance =
                parseFloat(item.total) -
                (parseFloat(item.depo) + parseFloat(item.payment))

              const isLunas = balance === 0

              return (
                <tr key={key}>
                  <td className="text-center">{key + 1}</td>
                  <td className="text-center px-0">
                    <Button size="xs" color="ghost" onClick={() => { }}>
                      <IoSendSharp className="size-4" />
                    </Button>
                  </td>
                  <td>{item.trx_code}</td>
                  <td>{APPROVAL[item.approve_status]}</td>
                  <td>{moment(item.transaction_date).format("DD/MM/YYYY")}</td>
                  <td>#{item.delivery_no}</td>
                  <td>{item.memo}</td>
                  <td className="text-right">
                    {convertNumber(parseFloat(item.total)).intoCurrency}
                  </td>
                  <td className="text-right">
                    {convertNumber(parseFloat(item.depo)).intoCurrency}
                  </td>
                  <td className="text-right">
                    {convertNumber(parseFloat(item.payment)).intoCurrency}
                  </td>
                  <td className="text-right">
                    {convertNumber(balance).intoCurrency}
                  </td>
                  <td className={approvalView ? "hidden" : ""}>
                    <div className="flex justify-center items-center">
                      <Button
                        className="w-full"
                        type="button"
                        color="primary"
                        permission="ST14"
                        disabled={isLunas}
                        onClick={() =>
                          navigate(
                            `/sales/transaction/payment-invoice/${item.invoice_id}`
                          )
                        }>
                        {isLunas ? "LUNAS" : "PAYMENT"}
                      </Button>
                    </div>
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={12} className="text-center">
                <Loading
                  loading={paymentInvoice.isLoading}
                  errorText={
                    paymentInvoice.isLoading ? "" : "No data available"
                  }
                />
              </td>
            </tr>
          )}

          {paymentInvoice.data.length ? (
            <tr className="text-right font-bold">
              <td colSpan={7} className="text-center">
                TOTAL
              </td>
              <td>
                {convertNumber(calculatePaymentInvoice.total).intoCurrency}
              </td>
              <td>
                {convertNumber(calculatePaymentInvoice.depo).intoCurrency}
              </td>
              <td>
                {convertNumber(calculatePaymentInvoice.payment).intoCurrency}
              </td>
              <td>
                {convertNumber(calculatePaymentInvoice.balance).intoCurrency}
              </td>
              <td />
            </tr>
          ) : null}
        </tbody>
      </BaseTable>
    </div>
  )
}
