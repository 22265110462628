import { useEffect, useState } from "react"
import type { ErrorFetch, SuccessFetch } from "../../../../../../../../../../types"
import { DataList } from "../../../../../../types"
import { useApi } from "../../../../../../../../../../utils"

export function useDetail() {
  const api = useApi()
  const [data, setData] = useState<DataList | null>(null)
  const [dataError, setError] = useState<string | undefined>(undefined)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    api.get("/location/").then((res: SuccessFetch<{
      payload: {
        location_id: number
        location_name: string
        address: string
        description?: string
        country_id: string
        countries_id: number
        country_name: string
        province_id: string
        province_name: string
        regency_id: string
        regency_name: string
        subdistrict_id: string
        subdistrict_name: string
        village_id: string
        village_name: string
        color: string
      }
    }>) => {
      const data = res.data.payload

      setData(data)
    }).catch((err: ErrorFetch<{ detail: { message: string } }>) => {
      setError(err.response.data.detail.message)
    }).finally(() => {
      setLoading(false)
    })
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { data, dataError, isLoading }
}