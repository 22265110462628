/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup"
import { useDetailSalesOrder } from "pages/Sales/Transaction/CustomerOrder/utils/hooks"
import { useEffect, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import { useApi } from "utils"
import { today } from "utils/functions"
import { FormSection } from "./components"
import {
  formPaymentDepositCustomerSchema,
  PaymentDepositCustomerType
} from "./types"
import { submitMap } from "./utils/functions"

export default function PaymentDepositEdit() {
  const navigate = useNavigate()
  const api = useApi()
  const params = useParams()
  const sales = useDetailSalesOrder({
    sales_order_id: params?.sales_order_id ?? ""
  })

  const defaultValues: PaymentDepositCustomerType = useMemo(
    () => ({
      sales_order_id: sales.data?.dataso.sales_order_id ?? NaN,
      customer_id: sales.data?.dataso.customer_id ?? NaN,
      location_id: sales.data?.dataso.location_id ?? NaN,
      coa_id: NaN,
      coa_name: "",
      coa_balance: 0,
      link: sales.data?.dataso?.trx_code ?? "",
      project_id: NaN,
      trx_code: "",
      transaction_date: today(),
      amount: 0,
      memo: sales.data?.dataso?.memo ?? "payment deposit customer transaction",
      shipped: sales.data?.dataso?.address ?? "",
      no_cheque: ""
    }),
    [sales.data]
  )

  const onSubmit = async (value: PaymentDepositCustomerType) => {
    await toast
      .promise(api.post("/depositcustomer/add", submitMap(value)), {
        loading: "Loading...",
        success: (res) => res.data.message,
        error: (err) =>
          err.response.data.detail?.message ?? err.response.data.message
      })
      .then(() => {
        navigate(-1)
      })
      .catch((err) => {
        throw new Error(err)
      })
  }

  const methods = useForm<PaymentDepositCustomerType>({
    defaultValues,
    resolver: yupResolver(formPaymentDepositCustomerSchema)
  })

  useEffect(() => methods.reset(defaultValues), [defaultValues])

  return (
    <FormProvider {...methods}>
      <FormSection onSubmit={methods.handleSubmit(onSubmit)} />
    </FormProvider>
  )
}
