// React
import { useEffect } from "react"

// Components
import { Input, Textarea, Select, ColorInput } from "../../../../../../components"

// Form
import { Controller, useFormContext, useWatch } from "react-hook-form"

// Types
import type { DataList } from "../../types"

// Utils
import { useCountry, useProvince, useRegency, useSubdistrict, useVillage } from "./utils"

export function FormSection(props: {
  isDetail?: boolean
  isUpdate?: boolean
  action?: string
}) {

  const { control } = useFormContext<DataList>()

  return (
    <section>
      <Controller
        control={control}
        name="location_name"
        render={({ field }) => <Input {...field} label="NAME" value={field.value ?? ""} disabled={props.isDetail} />}
      />

      <Country isDisabled={Boolean(props.isDetail)} />
      <Province isDisabled={Boolean(props.isDetail)} />
      <Regency isDisabled={Boolean(props.isDetail)} />
      <Subdistrict isDisabled={Boolean(props.isDetail)} />
      <Village isDisabled={Boolean(props.isDetail)} />

      <Controller
        control={control}
        name="address"
        render={({ field, fieldState }) => <Textarea {...field} error={fieldState.error?.message} label="ADDRESS" value={field.value ?? ""} disabled={props.isDetail} />}
      />
      <Controller
        control={control}
        name="description"
        render={({ field, fieldState }) => <Textarea {...field } error={fieldState.error?.message} label="NOTE" value={field.value ?? ""} disabled={props.isDetail} />}
      />

      <Controller
        control={control}
        name="color"
        render={({ field, fieldState }) => (
          <ColorInput
            {...field}
            label="Color"
            disabled={props.isDetail}
            error={fieldState.error?.message}
          />
        )}
      />
    </section>
  )
}

function Country(props: { isDisabled: boolean }) {
  const { control } = useFormContext<DataList>()
  const { data, isLoading } = useCountry()

  return (
    <Controller
      control={control}
      name="country_id"
      render={({ field, fieldState }) => (
        <Select
          label="COUNTRY"
          placeholder="Choose country"
          isDisabled={props.isDisabled}
          isLoading={isLoading}
          error={fieldState.error?.message}
          options={data}
          value={data.find(item => item.value === field.value)}
          onChange={e => field.onChange(e?.value)}
        />
      )}
    />
  )
}
function Province(props: { isDisabled: boolean }) {
  const { control } = useFormContext<DataList>()
  const country_id = useWatch({
    control,
    name: "country_id"
  })
  const { data, isLoading, refetch } = useProvince()

  useEffect(() => {
    refetch(country_id)
    // eslint-disable-next-line
  }, [country_id])

  return (
    <Controller
      control={control}
      name="province_id"
      render={({ field, fieldState }) => (
        <Select
          label="PROVINCE"
          placeholder="Choose province"
          isDisabled={props.isDisabled}
          isLoading={isLoading}
          error={fieldState.error?.message}
          options={data}
          value={data.find(item => item.value === field.value)}
          onChange={e => field.onChange(e?.value)}
        />
      )}
    />
  )
}
function Regency(props: { isDisabled: boolean }) {
  const { control } = useFormContext<DataList>()
  const province_id = useWatch({
    control,
    name: "province_id"
  })
  const { data, isLoading, refetch } = useRegency()

  useEffect(() => {
    refetch(province_id)
    // eslint-disable-next-line
  }, [province_id])

  return (
    <Controller
      control={control}
      name="regency_id"
      render={({ field, fieldState }) => (
        <Select
          label="REGENCY"
          placeholder="Choose regency"
          isDisabled={props.isDisabled}
          isLoading={isLoading}
          error={fieldState.error?.message}
          options={data}
          value={data.find(item => item.value === field.value)}
          onChange={e => field.onChange(e?.value)}
        />
      )}
    />
  )
}
function Subdistrict(props: { isDisabled: boolean }) {
  const { control } = useFormContext<DataList>()
  const regency_id = useWatch({
    control,
    name: "regency_id"
  })
  const { data, isLoading, refetch } = useSubdistrict()

  useEffect(() => {
    refetch(regency_id)
    // eslint-disable-next-line
  }, [regency_id])

  return (
    <Controller
      control={control}
      name="subdistrict_id"
      render={({ field, fieldState }) => (
        <Select
          label="SUBDISTRICT"
          placeholder="Choose subdistric"
          isDisabled={props.isDisabled}
          isLoading={isLoading}
          error={fieldState.error?.message}
          options={data}
          value={data.find(item => item.value === field.value)}
          onChange={e => field.onChange(e?.value)}
        />
      )}
    />
  )
}
function Village(props: { isDisabled: boolean }) {
  const { control } = useFormContext<DataList>()
  const subdistrict_id = useWatch({
    control,
    name: "subdistrict_id"
  })
  const { data, isLoading, refetch } = useVillage()

  useEffect(() => {
    refetch(subdistrict_id)
    // eslint-disable-next-line
  }, [subdistrict_id])

  return (
    <Controller
      control={control}
      name="village_id"
      render={({ field, fieldState }) => (
        <Select
          label="VILLAGE"
          placeholder="Choose village"
          isDisabled={props.isDisabled}
          isLoading={isLoading}
          error={fieldState.error?.message}
          options={data}
          value={data.find(item => item.value === field.value)}
          onChange={e => field.onChange(e?.value)}
        />
      )}
    />
  )
}