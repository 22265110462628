import { ActionButton, HeaderMenu, PermissionLink } from "components"
import { FormProvider, useForm } from "react-hook-form"
import { FormSection } from "../FormSection"
import { validationSchema } from "../../utils/vars"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormType } from "../../types"
import { useLocation } from 'react-router-dom';
import toast from "react-hot-toast"
import { useApi } from "utils"
import { FormAction } from "../FormAction"
import { Fragment } from "react"

export type DefaultValues = Pick<FormType, 'transaction_date' | 'location_id' | 'product_id' | 'mr_unit_id' | 'av_price_unit' | 'soh'>
export function Update(props: { data: FormType[]; }) {
    // const location = useLocation()

    return (
        <PermissionLink permission={"IT083"} to={"/inventory/transaction/opening-stock/update"} state={{ data: props?.data }}>
            <ActionButton.Update permission={"IT083"} className="join-item" />
        </PermissionLink>
    )
}

export function UpdateAction() {
    const api = useApi()
    const { state } = useLocation();

    const defaultValues: DefaultValues = {
        transaction_date: state?.data?.transaction_date,
        location_id: state?.data?.location_id,
        product_id: state?.data?.product_id,
        mr_unit_id: state?.data?.mr_unit_id,
        av_price_unit: '',
        soh: state?.data?.soh % 1 === 0 ? Math.floor(state?.data?.soh).toLocaleString("id-ID") : state?.data?.soh.replace(/(\.\d*?)0+$/, '$1').replace(/\.$/, '')
    }
    const methods = useForm<DefaultValues>({
        defaultValues,
        resolver: yupResolver(validationSchema)
    })
    const onSubmit = (value: DefaultValues) => {
        return new Promise<void>((resolve) => {
            toast.promise(
                api.put(`/opening-stock/update?Opening_Stock_Id=${state?.data?.opening_stock_id}`,
                    {
                        transaction_date: value.transaction_date,
                        location_id: value.location_id,
                        product_id: value.product_id,
                        av_price_unit: value.av_price_unit,
                        soh: value.soh
                    }),
                {
                    loading: "Loading...",
                    success: (res) => res.data.message,
                    error: (err) => {
                        if (err.response && err.response.status === 400) {
                            return err.response.data.message || err.response.data.detail.message;
                        } else {
                            return "An error occurred";
                        }
                    }
                }
            ).then(() => {
                // Scroll to Top
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            }).catch(() => { }).finally(resolve)
        })
    }

    return (
        <Fragment>
            <section className="container my-5">
                <section>
                    <HeaderMenu title="UPDATE DATA | OPENING STOCK" />
                    <section className="mt-2">
                        <section className="mt-2">
                        <FormProvider {...methods}>
                                <FormAction
                                    onSubmit={methods.handleSubmit(onSubmit)}
                                >
                                    <FormSection/>
                                </FormAction>
                            </FormProvider>
                        </section>
                    </section>
                </section>
            </section>
        </Fragment>
    )
}