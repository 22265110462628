/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup"
import { Button, Modal } from "components"
import { APPROVAL } from "pages/Sales/Register/utils/vars"
import { Fragment, useContext, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { today, useApi, useHandleAuth, useToggle } from "utils"
import { ContextApproveInvoice } from ".."
import { useDropdownEmployee } from "../../CustomerOrder/utils/hooks/useDropdownEmployee"
import { ReturnItemsList } from "../../ReturnItems/utils/hooks"
import { FormApproveReturnItems, formApproveReturnItemsSchema } from "../types"
import { FormSection } from "./FormSection"

type ApproveActionProps = {
  employees: ReturnType<typeof useDropdownEmployee>["dropdown"]
  data: ReturnItemsList
}

export function ApproveAction({ employees, data }: ApproveActionProps) {
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <Button
        className="w-full"
        type="button"
        color="primary"
        permission="ST18"
        onClick={toggle}
        disabled={[APPROVAL.REJECT, APPROVAL.APPROVE].includes(
          data?.approve_status
        )}>
        {APPROVAL[data?.approve_status]}
      </Button>

      {isActive && (
        <ModalSection employees={employees} toggle={toggle} data={data} />
      )}
    </Fragment>
  )
}

type ModalSectionProps = {
  employees: ReturnType<typeof useDropdownEmployee>["dropdown"]
  toggle: ReturnType<typeof useToggle>["toggle"]
  data: ApproveActionProps["data"]
}

function ModalSection({ employees, toggle, data }: ModalSectionProps) {
  const api = useApi()
  const context = useContext(ContextApproveInvoice)
  const handleAuth = useHandleAuth()
  const tokenData = handleAuth.userTokenData()
  const employee = employees?.find(
    (e) => Number(e.value) === tokenData?.user_id
  )

  const defaultValues: FormApproveReturnItems = useMemo(
    () => ({
      return_id: data.return_id,
      approve_date: today(),
      trx_code: data.trx_code,
      employee_id: tokenData?.user_id!,
      employee_name: employee?.employee_name,
      job_position_name: employee?.job_position_name ?? "",
      approval_status: APPROVAL.APPROVE
    }),
    [employees, data]
  )

  const methods = useForm<FormApproveReturnItems>({
    defaultValues,
    resolver: yupResolver(formApproveReturnItemsSchema)
  })

  const onSubmit = async (value: FormApproveReturnItems) => {
    const submitValue = {
      return_id: value.return_id!,
      approval_status: value.approval_status
    }

    await toast
      .promise(api.post("/return/approve", null, { params: submitValue }), {
        loading: "Loading...",
        success: (res) => res.data.message,
        error: (err) =>
          err.response.data.detail?.message ?? err.response.data.message
      })
      .then(() => {
        methods.reset(defaultValues)
        context.refetch()
        toggle()
      })
  }

  return (
    <Modal isOpen title="APPROVE RETURN ITEMS" closeModal={toggle} size="lg">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Modal.Body>
            <FormSection />
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" color="primary" permission="ST11">
              SAVE
            </Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}
