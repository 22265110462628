// React
import { Fragment } from "react"

// Components
import { BaseTable, Card, CommandButton, HeaderMenu, Loading } from "components"
import { DeletePurchaseOrderModal } from "pages/Purchase/components"
import { Acc, ActionButton, AddReminder, Date, Deliver, Freight, Memo, PaymentApprovedSection, PromisedDate, RecurringButton, Supplier, SupplierId, TaxInclusive, TradingTerm } from "../components"
import { Code, Tab, Table } from "./components"

// Contexts
import { DataJournalContextProvider } from "contexts"
import { ReminderContext } from "../contexts"
import { PurchaseOrderContext } from "./contexts"

// Form
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Third-Party Libraries
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"

// Types
import type { DetailDeleteType } from "pages/Purchase/types"
import type { PurchaseOrderType } from "./types"

// Utils
import { freightAlert, useApi, useToggle } from "utils"
import { default_option } from "pages/Purchase/Register/utils"
import { type FormType, getFinalValue, useDetail, validation_schema } from "./utils"

export default function EditPurchaseOrder(): JSX.Element {
  // Hooks
  const { id } = useParams()
  const { data, isLoading } = useDetail(id!)

  if (isLoading || !data) {
    return (
      <Loading
        errorText="No data available"
        loading={isLoading}
      />
    )
  }

  return (
    <PurchaseOrderContext.Provider value={{ disabled: false }}>
      <FormSection
        data={data!}
        id={id!}
      />
    </PurchaseOrderContext.Provider>
  )
}

function FormSection(params: {
  data: PurchaseOrderType
  id: string
}): JSX.Element {
  // Vars
  const { trx_code, po_delivery_status, ...rest } = params.data
  const isPending: boolean = Boolean(po_delivery_status === null || po_delivery_status === 1)

  // Hooks
  const api = useApi()
  const navigate = useNavigate()

  // Functions
  const toRegister = (): void => {
    navigate("/purchase/register", { replace: true })
  }

  // Form
  const default_values: FormType = rest
  const onSubmit = (value: FormType) => {
    // Vars
    const final_value = getFinalValue(value)

    return new Promise<void>((resolve) => {
      // Functions
      const submitForm = (): void => {
        toast.promise(
          api.put("/purchaseorder/update", final_value),
          {
            loading: "Loading...",
            success: (res) => res.data.message,
            error: (err) => err.response.data.detail?.message ?? err.response.data.message
          }
        ).then(() => {
          toRegister()
        }).catch(() => {}).finally(resolve)
      }

      if (!value.freight) {
        freightAlert("Purchase Order").then(() => {
          submitForm()
        }).catch(() => {
          return resolve()
        })
      } else {
        submitForm()
      }
    })
  }
  const methods = useForm<FormType>({
    defaultValues: default_values,
    resolver: yupResolver(validation_schema)
  })

  return (
    <FormProvider {...methods}>
      <section className="container my-5 flex flex-col gap-2">
        <HeaderMenu title="DATA ENTRY | PURCHASE ORDER">
          <Code value={trx_code} />
        </HeaderMenu>

        <section className="grid lg:grid-cols-3 gap-x-6 gap-y-3 items-end">
          <Supplier
            disabled
            name="vendor_id"
            trading_term_name="payment_term"
            vendor_name="_vendor_name"
            detail="podetail"
          />

          <TradingTerm name="payment_term" />
          <TaxInclusive name="tax_inclusive" />
        </section>

        <Card>
          <Card.Body className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
            <section className="flex flex-col gap-3">
              <Deliver
                deliver_name="location_id"
                detail_name="address"
                disabled={!isPending}
              />

              <Memo
                name="memo"
                disabled={!isPending}
              />
            </section>

            <section className="flex flex-col gap-3">
              <Date
                name="transaction_date"
                disabled={!isPending}
              />

              <SupplierId
                name="referensi"
                disabled={!isPending}
              />

              <PromisedDate
                name="promize_date"
                disabled={!isPending}
              />

              <ReminderContext.Provider
                value={{
                  disabled: false,
                  transaction_from: "PURCHASE ORDER",
                  name: {
                    memo: "memo",
                    promise_date: "promize_date",
                    reminder: "reminder",
                    referensi: "referensi",
                    transaction_date: "transaction_date",
                    vendor_name: "_vendor_name",
                    reminder_child: {
                      memo: "reminder.memo",
                      promise_date: "reminder.promise_date",
                      transaction_date: "reminder.transaction_date"
                    }
                  },
                  onSuccess: (value) => {
                    methods.setValue("reminder.memo", value.memo)
                    methods.setValue("reminder.promise_date", value.promise_date)
                    methods.setValue("reminder.transaction_date", value.transaction_date)
                  }
                }}
              >
                <AddReminder  />
              </ReminderContext.Provider>
            </section>
          </Card.Body>
        </Card>

        <DataJournalContextProvider>
          <Table />

          <section className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
            <section className="flex flex-col gap-3">
              <BaseTable
                plain
                className="text-right"
              >
                <tbody>
                  <tr>
                    <td className="w-[1px] whitespace-nowrap">PAYMENT STATUS</td>
                    <td>{params.data.po_payment_status_text}</td>
                  </tr>

                  <PaymentApprovedSection
                    payment_status={default_option[methods.getValues("approval_status")]?.label ?? "-"}
                    approved_by={params.data.approve_by_name}
                  />
                </tbody>
              </BaseTable>

              <Freight name="freight" />
              <RecurringButton />
            </section>

            <Acc
              name={{
                detail: "podetail",
                freight: "freight"
              }}
            />
          </section>
        </DataJournalContextProvider>

        <Tab />

        <ActionButton>
          <ActionButton.LeftSide />

          <section className="flex flex-wrap gap-3">
            <Delete
              onSuccess={toRegister}
              item={{
                purchase_order_id: params.id,
                transaction_date: params.data.transaction_date,
                trx_code: params.data.trx_code,
                vendor_name: params.data._vendor_name,
              }}
            />

            <CommandButton
              actiontype="save"
              permission={"PT021"}
              loading={methods.formState.isSubmitting ? "true" : undefined}
              onClick={methods.handleSubmit(onSubmit)}
            />
          </section>
        </ActionButton>
      </section>
    </FormProvider>
  )
}

function Delete(params: {
  item: DetailDeleteType
  onSuccess: () => void
}) {
  // Hooks
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <CommandButton permission={"PT024"} actiontype="delete" onClick={toggle} />

      {isActive && (
        <DeletePurchaseOrderModal
          item={params.item}
          onSuccess={params.onSuccess}
          toggle={toggle}
        />
      )}
    </Fragment>
  )
}