/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import { useApi } from "utils"
import { today } from "utils/functions"
import { FormSection } from "./components"
import { FormSalesOrderType, formSalesOrderSchema } from "./types"
import { freightAlert, submitMap } from "./utils/functions"
import { useDetailSalesOrder } from "./utils/hooks"

export default function CustomerOrderEdit() {
  const navigate = useNavigate()
  const api = useApi()
  const params = useParams()
  const sales = useDetailSalesOrder({
    sales_order_id: params?.sales_order_id ?? ""
  })

  const defaultValues: FormSalesOrderType = useMemo(
    () => ({
      trx_code: sales.data?.dataso?.trx_code ?? "",
      sales_order_id: sales.data?.dataso.sales_order_id ?? NaN,
      customer_id: sales.data?.dataso.customer_id ?? NaN,
      employee_id: sales.data?.dataso.employee_id ?? NaN,
      payment_term: sales.data?.dataso.payment_term ?? NaN,
      location_id: sales.data?.dataso.location_id ?? NaN,
      memo: sales.data?.dataso?.memo ?? "sales order transaction",
      promize_date: sales.data?.dataso?.promize_date ?? today(),
      referensi: sales.data?.dataso?.referensi ?? "",
      tax_inclusive: true,
      transaction_date: sales.data?.dataso?.transaction_date ?? today(),
      shipped: sales.data?.dataso?.address ?? "",
      payment_term_name: sales.data?.dataso?.payment_term_name ?? "",
      price_level_name: sales.data?.dataso?.price_level_name ?? "",
      sodetail:
        sales.data?.detailso?.map((item) => ({
          sales_order_detail_id: item.sales_order_detail_id,
          product_id: item.product_id,
          product_name: item.product_name,
          product_barcode: item.product_barcode,
          qty_order: item.qty_order,
          received: item.received ?? 0,
          mr_unit_id: item.mr_unit_id,
          mr_unit_name: item.mr_unit_name,
          price_measure: item.price_measure,
          discount: item.discount,
          total: item.total,
          project_id: item.project_id,
          project_name: item.project_name,
          tax_id: item.tax_id,
          tax_name: `${item.tax_name} (${item.tax_rate}%)`,
          tax_rate: item.tax_rate,
          location_id: item.location_id,
          location_name: item.location_name,
          soh: item?.soh ?? 0
        })) ?? [],
      freight: sales.data?.dataso?.freight ?? 0,
      approved_by: sales.data?.dataso?.approve_by ?? "",
      approved_by_name: sales.data?.dataso?.approve_by_name ?? "",
      approval_status: sales.data?.dataso?.approval_status ?? 1,
      approval_status_text: sales.data?.dataso?.approval_status_text ?? "",
      so_payment_status: sales.data?.dataso?.so_payment_status ?? 1,
      so_payment_status_text: sales.data?.dataso?.so_payment_status_text ?? "",
      reminder: Object.is(sales.data?.reminder, null)
        ? null
        : {
          card_group: sales.data?.reminder?.card_group ?? NaN,
          card_id: sales.data?.reminder?.card_id ?? NaN,
          memo: sales.data?.reminder?.memo ?? "",
          promise_date: sales.data?.reminder?.promise_date ?? today(),
          transaction_date: sales.data?.reminder?.transaction_date ?? today(),
          trx_code: sales.data?.reminder?.trx_code ?? "",
          vendor_id: sales.data?.reminder?.card_id ?? NaN
        }
    }),
    [sales.data]
  )

  const submitPromise = async (value: FormSalesOrderType) => {
    return await toast
      .promise(api.put("/salesorder/update", submitMap(value)), {
        loading: "Loading...",
        success: (res) => res.data.message,
        error: (err) =>
          err.response.data.detail?.message ?? err.response.data.message
      })
      .then(() => {
        navigate("/sales/register/", { replace: true })
      })
      .catch((err) => {
        throw new Error(err)
      })
  }

  const onSubmit = async (value: FormSalesOrderType) => {
    if (value.freight && value.freight > 0) {
      return await submitPromise(value)
    }

    await freightAlert().then(async (res) => {
      if (res.isConfirmed) {
        await submitPromise(value)
      }
    })
  }

  const methods = useForm<FormSalesOrderType>({
    defaultValues,
    resolver: yupResolver(formSalesOrderSchema)
  })

  useEffect(() => methods.reset(defaultValues), [defaultValues])

  return (
    <FormProvider {...methods}>
      <FormSection
        isEdit
        onSubmit={methods.handleSubmit(onSubmit)}
      />
    </FormProvider>
  )
}
