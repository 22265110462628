// React
import { useContext } from "react"

// Components
import { BaseTable, Card, CommandButton, HeaderMenu, Label, Loading } from "components"
import { ActionButton } from "../components"
import { Account, Balance, BalanceBill, Code, JournalList, LinkBill, Location, Memo, PaymentAmount, PaymentDeposit, Referensi, TotalBalance, TransactionDate, Vendor } from "./components"

// Contexts
import { AccountProvider } from "contexts"
import { BillContext } from "./contexts"

// Form
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Third-Party Libraries
import moment from "moment"
import { useNavigate, useParams } from "react-router-dom"

// Utils
import { FormType, useBillDetail, validationSchema } from "./utils"
import toast from "react-hot-toast"
import { useApi } from "utils"

export default function PaymentBill(): JSX.Element {
  // Hooks
  const { bill_id } = useParams<{ bill_id: string }>()
  const { data, isLoading } = useBillDetail(bill_id!)

  if (isLoading || !data) {
    return <Loading loading={isLoading} />
  }

  return (
    <BillContext.Provider value={data}>
      <AccountProvider>
        <FormSection />
      </AccountProvider>
    </BillContext.Provider>
  )
}

function FormSection(): JSX.Element {
  // Hooks
  const api = useApi()
  const data = useContext(BillContext)
  const navigate = useNavigate()
  const { bill_id } = useParams<{ bill_id: string }>()

  // Vars
  const databill = data.databill

  // Form
  const defaultValues: FormType = {
    _balance: 0,
    coa_id: "",
    memo: "Payment Bill transaction",
    deposit_amount: 0,
    payment_amount: 0,
    referensi: "",
    trx_code: "",
    transaction_date: moment().format("YYYY-MM-DD"),
    vendor_id: databill.vendor_id
  }
  const methods = useForm<FormType>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })
  const onSubmit = (value: FormType) => {
    // Vars
    const final_value = {
      bill_id,
      trx_code: value.trx_code,
      vendor_id: value.vendor_id,
      location_id: databill.location_id,
      address: databill.address,
      memo: value.memo,
      transaction_date: value.transaction_date,
      referensi: value.referensi,
      coa_id: value.coa_id,
      deposit_amount: value.deposit_amount,
      payment_amount: value.payment_amount
    }
    const isBelowBalance: boolean = databill.balance - value.deposit_amount - value.payment_amount < 0

    return new Promise<void>((resolve) => {
      // Functions
      const submitForm = (): void => {
        toast.promise(
          api.post("/paymentbill/add", final_value),
          {
            loading: "Loading...",
            success: (res) => res.data.message,
            error: (err) => err.response.data.detail?.message ?? err.response.data.message
          }
        ).then(() => {
          navigate(-1)
        }).catch(() => {}).finally(resolve)
      }

      if (isBelowBalance) {
        toast.error("Payment Deposit & Payment Amount cannot be more than Balance Bill")
        return resolve()
      }

      submitForm()
    })
  }

  return (
    <section className="container my-5 flex flex-col gap-2">
      <FormProvider {...methods}>
        <HeaderMenu title="DATA ENTRY | PAYMENT BILL">
          <Code />
        </HeaderMenu>

        <section className="grid lg:grid-cols-2 gap-3">
          <Account />
          <Balance />
        </section>

        <Card>
          <Card.Body className="grid lg:grid-cols-2 items-stretch gap-3">
            <section className="flex flex-col gap-3">
              <section className="grow flex flex-col gap-3">
                <Vendor />
                <Memo />
                <Location />
              </section>

              <div className="justify-self-end">
                <section className="pt-auto">
                  <LinkBill />
                </section>
              </div>
            </section>

            <section className="flex flex-col gap-3">
              <Referensi />
              <TransactionDate />
              <BalanceBill />
              <PaymentDeposit />
              <PaymentAmount />
              <TotalBalance />
            </section>
          </Card.Body>
        </Card>

        <Label text="JOURNAL ENTRY" />

        <BaseTable>
          <thead>
            <tr>
              <th>DATE</th>
              <th>ID#</th>
              <th>ACCOUNT</th>
              <th>MEMO</th>
              <th>DEBET</th>
              <th>CREDIT</th>
            </tr>
          </thead>

          <tbody>
            <JournalList.PaymentAccount />
            <JournalList.PaymentDeposit />
            <JournalList.Total />
          </tbody>
        </BaseTable>
      </FormProvider>

      <ActionButton>
        <section className="flex flex-wrap gap-3">
          <CommandButton actiontype="help" />
          <CommandButton actiontype="print" />
          <CommandButton actiontype="email" />
          <CommandButton actiontype="journal" />
          <CommandButton actiontype="attachment" />
        </section>

        <section className="flex flex-wrap gap-3">
          <CommandButton
            actiontype="save"
            loading={methods.formState.isSubmitting ? "true" : undefined}
            onClick={methods.handleSubmit(onSubmit)}
          />
        </section>
      </ActionButton>
    </section>
  )
}