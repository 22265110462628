/* eslint-disable react-hooks/exhaustive-deps */

import { APPROVAL } from "pages/Sales/Register/utils/vars"
import { useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi } from "utils"

type UseSOInvoiceListProps = {
  sales_order_id: string
  Approval?: APPROVAL
}

type Response = {
  payload: {
    address: string
    approve_by?: string
    approve_date?: string
    approve_status: number
    balance: number
    customer_id: number
    customer_name: string
    delivery_no: number
    freight: number
    invoice_id: number
    location_id: number
    location_name: string
    memo: string
    referensi: string
    sales_order_id: number
    total_deposit: number
    total_invoice: number
    total_payment: number
    transaction_date: string
    trx_code: string
  }[]
}

export function useSOInvoiceList({
  sales_order_id,
  Approval,
}: UseSOInvoiceListProps) {
  const api = useApi()
  const [data, setData] = useState<Response["payload"]>([])
  const [isLoading, setLoading] = useState(false)

  const fetchData = () => {
    setLoading(true)

    const params = {
      sales_order_id: Number(sales_order_id),
      Approval,
    }

    api
      .post("/invoice/byso", null, { params })
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload ?? []
        setData(data)
      })
      .catch(() => setData([]))
      .finally(() => setLoading(false))
  }

  const refetch = () => fetchData()

  useEffect(() => {
    fetchData()

    return () => {
      setData([])
      setLoading(false)
    }
  }, [sales_order_id])

  return {
    data,
    isLoading,
    refetch,
    dropdown: data.map((item) => ({
      value: item.invoice_id,
      label: item.trx_code,
    })),
  }
}
