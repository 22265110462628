import { Fragment } from "react"
import { ActionButton, Modal } from "../../../../../../../../../components"
import { FormSection } from "../../../../FormSection"
import { FormProvider, useForm } from "react-hook-form"
import type { DataList } from "../../../../../types"
import { useToggle } from "../../../../../../../../../utils"
import { menuTitle } from "./MenuTitle"

export function Detail(props: { id: DataList }) {
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <ActionButton.Detail className="join-item" onClick={toggle} />
      {isActive && <ModalSection id={props.id} toggle={toggle} />}
    </Fragment>
  )
}

function ModalSection(props: {
  id: DataList
  toggle: () => void
}) {

  return (
    <Modal isOpen title={`DETAIL ${menuTitle.title}`} size="xl" closeModal={props.toggle}>
        <DataForm data={props.id!} toggle={props.toggle} />
    </Modal>
  )
}


function DataForm(props: {
  data: DataList
  toggle: () => void
}) {

  const defaultValues = {
    module_id: props?.data?.module?.module_id,
    menu_code: props?.data?.menu_code,
    menu_name: props?.data?.menu_name,
    description: props?.data?.description,
  }
  const methods = useForm({ defaultValues })

  return (
    <FormProvider {...methods}>
      <FormSection isDetail action="DETAIL" />
    </FormProvider>
  )
}