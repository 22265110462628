import { Fragment, useState } from "react"
import { Card, Loading, Button, PaginationData, TableNumber, CommandButton, BaseTable } from "../../../../components"
import { useList } from "../../Transaction/AddNewItem/utils"
import { ProductList } from "../../Transaction/AddNewItem/types"
import { default_pagination_value } from "utils"

interface PaginationState {
  total: number;
  page: number;
  limit: number;
  pages: number;
  keyword: string;
  product_group?: string;
}
export function ItemsTransfer() {
  const [ pagination, setPagination ] = useState<PaginationState>(default_pagination_value)
  const { data, isLoading } = useList(pagination)
  const dataList: ProductList[] = data.items

  const dataLength = data.pagination_data.size
  const pageCurrent = pagination.page
  const limit = pagination.limit
  const totalData = pagination.total

  return (
    <Fragment>
      <section className="container my-2">
        {isLoading 
          ? <Loading loading />
          : <>
              <section className="mt-2">
                <Card>
                  <Card.Body>
                    <div className="flex justify-end">
                      <Button type="button" color="primary" className="mr-3">All</Button>
                      <Button type="button" color="primary" className="mr-3">Received</Button>
                      <Button type="button" color="primary" className="mr-3">Shipped</Button>
                      <Button type="button" color="primary">On Order</Button>
                    </div>
                  </Card.Body>
                </Card>
              </section>
              <section className="mt-2">
                <Card>
                  <Card.Body>
                    <BaseTable className="border-collapse">
                      <thead>
                        <tr>
                          <th>ID NUMBER</th>
                          <th>DATE</th>
                          <th>REQUEST</th>
                          <th>APPROVED</th>
                          <th>RECEIVED</th>
                          <th>FROM</th>
                          <th>TO</th>
                          <th>MEMO</th>
                          <th>SENT QTY</th>
                          <th>RCV QTY</th>
                          <th>RJK QTY</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataList.length === 0 
                          ? <tr><td colSpan={10} className="text-center"><Loading errorText="No data available" /></td></tr>
                          : <Fragment>
                              {dataList.map((item: any, key: any) => (
                                <tr className={item.header_level === 3 ? "font-normal" : "font-bold"}>
                                  <td>{TableNumber({ dataLength: dataLength, pageCurrent: pageCurrent, index: key, limit: limit, totalData: totalData })}</td>
                                  <td className="text-center">{item.account_type_name ?? "BSI"}</td>
                                  <td className="text-center"><input type="checkbox" className="checkbox checkbox-primary" checked={!item.cheque_coa ? true : false} disabled /></td>
                                  <td>{item.account_type_name ?? "CG-001-05249.005"}</td>
                                  <td>{item.account_type_name ?? "9 POLY 65% VISCOSE 35% UNIFORM  TOP FML MANDARIN V-NECK S/S 2 PR BEIGE"}</td>
                                  <td className="text-right">{item.account_type_name ? item.opening_balance?.toLocaleString("id-ID", { maximumFractionDigits: 0 }) : "12.000.000"}</td>
                                  <td className="text-center">{item.account_type_name ?? "lusin"}</td>
                                  <td className="text-center">{item.account_type_name ?? "100"}</td>
                                  <td className="text-center">{item.account_type_name ?? "pcs"}</td>
                                  <td className="text-right">{item.account_type_name ?? "250.000"}</td>
                                  <td className="text-center">{item.account_type_name ?? "156"}</td>
                                </tr>
                              ))}
                            </Fragment>
                        }
                      </tbody>
                    </BaseTable>
                    <div className="mt-5">
                      <PaginationData data={data} pagination={pagination} setPagination={setPagination} />
                    </div>
                  </Card.Body>
                </Card>
              </section>
              <section className="mt-2">
                <Card>
                  <Card.Body className="flex flex-col gap-5">
                    <section className="flex flex-wrap justify-between items-center gap-3">
                      <section className="flex flex-wrap gap-3">
                        <CommandButton actiontype="help" />
                        <CommandButton actiontype="print" />
                        <CommandButton actiontype="email" />
                        <CommandButton actiontype="export" />
                      </section>
                    </section>
                  </Card.Body>
                </Card>
              </section>
            </>
        }
      </section>
    </Fragment>
  )
}