import { Input, Select, Textarea } from "../../../../../../components"
import { Controller, useFormContext } from "react-hook-form"
import type { DataList } from "../../types"
import { menuTitle } from "../ChildItem/components/Item/components/MenuTitle"
import { useModule } from "../../utils"

export function FormSection(props: {
  isDetail?: boolean
  isUpdate?: boolean
  action?: string
}) {

  const { control } = useFormContext<DataList>();
  
  // Hooks
  const { data, isLoading } = useModule()

  return (
    <section>
      <Controller
        control={control}
        name="menu_code"
        render={({ field, fieldState }) => <Input {...field} label="CODE" error={fieldState.error?.message} value={field.value ?? ""} disabled={props.action === "DETAIL" || props.action === "DELETE"} />}
      />

      <Controller
        control={control}
        name="module_id"
        render={({ field, fieldState }) => (
          <Select
            label="MODULE"
            placeholder="Select Module"
            error={fieldState.error?.message}
            isLoading={isLoading}
            isDisabled={Boolean(props?.action === 'DELETE' || props?.action === 'DETAIL')}
            options={data}
            value={data.find(item => item.value === field.value?.toString())}
            onChange={e => field.onChange(e?.value)}
          />
        )}
      />

      <Controller
        control={control}
        name="menu_name"
        render={({ field, fieldState }) => <Input {...field} label={menuTitle.title} error={fieldState.error?.message} value={field.value ?? ""} disabled={props.action === "DETAIL" || props.action === "DELETE"} />}
      />

      <Controller
        control={control}
        name="description"
        render={({ field, fieldState }) => <Textarea {...field} label="DESCRIPTION" error={fieldState.error?.message} value={field.value ?? ""} disabled={props.action === "DETAIL" || props.action === "DELETE"} />}
      />
    </section>
  )
}