// React
import { useContext } from "react"

// Components
import { BaseTable, Card, CommandButton, HeaderMenu, Label, Loading } from "components"
import { ActionButton } from "../components"
import { Account, Amount, Balance, Body, Cheque, Code, LinkPurchase, Location, Memo, TransactionDate, Vendor } from "./components"

// Contexts
import { AccountProvider } from "contexts"
import { PoContext } from "./contexts"

// Form
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Third-Party Libraries
import moment from "moment"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"

// Utils
import { useApi } from "utils"
import { type FormType, usePoDetail, validationSchema } from "./utils"

export default function DepositVendor(): JSX.Element {
  // Hooks
  const { purchase_order_id } = useParams<{ purchase_order_id: string }>()
  const { data, isLoading } = usePoDetail(purchase_order_id!)

  if (isLoading || !data) {
    return <Loading loading={isLoading} />
  }

  return (
    <PoContext.Provider value={data}>
      <AccountProvider>
        <FormSection />
      </AccountProvider>
    </PoContext.Provider>
  )
}

function FormSection(): JSX.Element {
  // Hooks
  const api = useApi()
  const data = useContext(PoContext)
  const navigate = useNavigate()
  const { purchase_order_id } = useParams<{ purchase_order_id: string }>()

  // Vars
  const datapo = data.datapo

  // Form
  const defaultValues: FormType = {
    _balance: 0,
    amount: 0,
    coa_id: "",
    memo: "Payment deposit transaction",
    no_cheque: "",
    trx_code: "",
    transaction_date: moment().format("YYYY-MM-DD"),
    vendor_id: datapo.vendor_id
  }
  const onSubmit = (value: FormType) => {
    const finalValue = {
      purchase_order_id,
      coa_id: value.coa_id,
      vendor_id: value.vendor_id,
      location_id: datapo.location_id,
      project_id: null,
      trx_code: value.trx_code,
      transaction_date: value.transaction_date,
      amount: value.amount,
      memo: value.memo,
      no_cheque: value.no_cheque
    }

    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/depositvendor/add", finalValue),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.detail?.message ?? err.response.data.message
        }
      ).then(() => {
        navigate(-1)
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm<FormType>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })
  
  return (
    <FormProvider {...methods}>
      <section className="container my-5 flex flex-col gap-2">
        <HeaderMenu title="DATA ENTRY | PAYMENT (DEPOSIT)">
          <Code />
        </HeaderMenu>

        <section className="grid lg:grid-cols-2 gap-3">
          <Account />
          <Balance />
        </section>

        <Card>
          <Card.Body className="grid lg:grid-cols-2 items-stretch gap-3">
            <section className="flex flex-col gap-3">
              <section className="grow flex flex-col gap-3">
                <Vendor />
                <Memo />
              </section>

              <div className="justify-self-end">
                <section className="pt-auto">
                  <LinkPurchase />
                </section>
              </div>
            </section>

            <section className="flex flex-col gap-3">
              <Cheque />
              <TransactionDate />
              <Amount />
              <Location />
            </section>
          </Card.Body>
        </Card>

        <Label text="JOURNAL ENTRY" />

        <BaseTable>
          <thead>
            <tr>
              <th>DATE</th>
              <th>ID#</th>
              <th>ACCOUNT</th>
              <th>MEMO</th>
              <th>DEBET</th>
              <th>CREDIT</th>
            </tr>
          </thead>

          <tbody>
            <Body />
          </tbody>
        </BaseTable>

        <ActionButton>
          <section className="flex flex-wrap gap-3">
            <CommandButton actiontype="help" />
            <CommandButton actiontype="print" />
            <CommandButton actiontype="email" />
            <CommandButton actiontype="journal" />
            <CommandButton actiontype="attachment" />
          </section>

          <section className="flex flex-wrap gap-3">
            <CommandButton actiontype="delete" />

            <CommandButton
              actiontype="save"
              loading={methods.formState.isSubmitting ? "true" : undefined}
              onClick={methods.handleSubmit(onSubmit)}
            />
          </section>
        </ActionButton>
      </section>
    </FormProvider>
  )
}