import { FormReturnItemsType, SubmitType } from "../../types"

export const submitMap = (data: FormReturnItemsType): SubmitType => {
  return {
    invoice_id: data.invoice_id ?? NaN,
    trx_code: data.trx_code ?? "",
    customer_id: data.customer_id,
    location_id: data.location_id,
    address: data.shipped,
    memo: data.memo,
    transaction_date: data.transaction_date,
    referensi: data.referensi,
    return_detail: [],
  }
}
