// React
import { useEffect, useState } from "react"

// Types
import type { SuccessFetch } from "types"
import type { BillType } from "../../types"

// Utils
import { useApi } from "utils"
import { default_option } from "pages/Purchase/utils"

export function useProbillList(purchase_order_id: string | undefined): {
  data: BillType[]
  isLoading: boolean
} {
  // Hooks
  const api = useApi()
  const [data, setData] = useState<BillType[]>([])
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (purchase_order_id) {
      api.post(`/probill/bypo?purchase_order_id=${purchase_order_id}`).then((res: SuccessFetch<{
        payload: {
          approve_status: number
          balance: number
          bill_id: number
          delivery_no: number
          memo: string
          total_bill: number
          total_payment: number
          transaction_date: string
          trx_code: string
        }[]
      }>) => {
        setData(res.data.payload.map(item => {
          return {
            approval: default_option.find(i => i.value === String(item.approve_status))?.label ?? "-",
            balance: item.balance,
            bill_id: item.bill_id.toString(),
            delivery_no: item.delivery_no,
            memo: item.memo,
            total_bill: item.total_bill,
            total_payment: item.total_payment,
            transaction_date: item.transaction_date,
            trx_code: item.trx_code
          }
        }))
      }).catch(() => {
        setData([])
      }).finally(() => {
        setLoading(false)
      })
    } else {
      setLoading(false)
    }

    return () => {
      setData([])
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { data, isLoading }
}