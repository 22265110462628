import { Fragment, useContext } from "react"
import { ActionButton, Button, Loading, Modal } from "../../../../../../../../../components"
import { FormSection } from "../../../../FormSection"
import * as yup from "yup"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { DataList } from "../../../../../types"
import { useApi, useToggle } from "../../../../../../../../../utils"
import { Render } from "../../../../../utils"
import { useDetail } from "../utils"
import { menuTitle } from "./MenuTitle"

export function Update(props: { id: DataList, refetch: () => void }) {
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <ActionButton.Update className="join-item" onClick={toggle} />
      {isActive && <ModalSection id={props.id} toggle={toggle} refetch={props.refetch} />}
    </Fragment>
  )
}

function ModalSection(props: {
  id: DataList
  toggle: () => void
  refetch: () => void 
}) {
  const { data, dataError, isLoading } = useDetail()

  return (
    <Modal isOpen title={`EDIT ${menuTitle.title}`} size="xl" closeModal={props.toggle}>
      {isLoading || dataError ? (
        <Loading errorText={dataError} loading={isLoading} />
      ) : (
        <DataForm data={data!} id={props.id} toggle={props.toggle} refetch={props.refetch} />
      )}
    </Modal>
  )
}

function DataForm(props: {
  data: DataList
  id: DataList
  toggle: () => void
  refetch: () => void 
}) {
  const api = useApi()
  const { toggle } = useContext(Render)

  const validationSchema = yup.object().shape({
    mr_product_size_code: yup.string().label("Size code").required().test('len', 'Must be exactly 3 characters', val => val.toString().length === 3),
    mr_product_size_name: yup.string().label("Size name").required(),
  })
  const onSubmit = ( value: DataList ) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.put(`/productsize/edit?mr_product_size_id=${props.id.mr_product_size_id}`, 
        { 
          mr_product_size_code: value.mr_product_size_code, 
          mr_product_size_name: value.mr_product_size_name, 
          description: value.description, 
        }),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => {
            if (err.response && err.response.status === 400) {
              return err.response.data.message || "Bad Request";
            } else {
              return "An error occurred";
            }
          }
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm({
    defaultValues: props.id,
    resolver: yupResolver(validationSchema)
  })

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormSection isUpdate />

        <Modal.Footer className="text-end">
          <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
        </Modal.Footer>
      </form>
    </FormProvider>
  )
}