/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup"
import { FormProvider, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { useApi, useHandleAuth } from "utils"
import { today } from "utils/functions"
import { FormSection } from "./components"
import { FormSalesOrderType, formSalesOrderSchema } from "./types"
import { freightAlert, submitMap } from "./utils/functions"

export default function CustomerOrder() {
  const api = useApi()
  const { userTokenData } = useHandleAuth()
  const userToken = userTokenData()

  const defaultValues: FormSalesOrderType = {
    customer_id: NaN,
    employee_id: userToken?.user_id ?? NaN,
    price_level_name: "",
    payment_term: NaN,
    location_id: NaN,
    memo: "sales order transaction",
    approval_status: 1,
    approval_status_text: "",
    promize_date: today(),
    referensi: "",
    tax_inclusive: true,
    transaction_date: today(),
    shipped: "",
    sodetail: [],
    freight: 0,
    approved_by: "",
    reminder: null,
    payment_term_name: ""
  }

  const submitPromise = async (value: FormSalesOrderType) => {
    return await toast
      .promise(api.post("/salesorder/add", submitMap(value)), {
        loading: "Loading...",
        success: (res) => res.data.message,
        error: (err) =>
          err.response.data.detail?.message ?? err.response.data.message
      })
      .then(() => {
        methods.reset(defaultValues)
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      })
      .catch((err) => {
        throw new Error(err)
      })
  }

  const onSubmit = async (value: FormSalesOrderType) => {
    if (value.freight && value.freight > 0) {
      return await submitPromise(value)
    }

    await freightAlert().then(async (res) => {
      if (res.isConfirmed) {
        await submitPromise(value)
      }
    })
  }

  const methods = useForm<FormSalesOrderType>({
    defaultValues,
    resolver: yupResolver(formSalesOrderSchema)
  })

  return (
    <FormProvider {...methods}>
      <FormSection
        onSubmit={() => {
          methods.handleSubmit(onSubmit)()

          if (
            ["payment_term", "price_level_name"].includes(
              Object.keys(methods.formState.errors)[0]
            )
          ) {
            Object.values(methods.formState.errors)
              ?.map((err) => err.message)
              .forEach((err) => toast.error(err ?? ""))
          }
        }}
      />
    </FormProvider>
  )
}
