// Pages
import TaxCode from "../pages/Setup/Preference/TaxCode"
import Bank from "../pages/Setup/Preference/Bank/index"
import CategoryCustomer from "../pages/Setup/Preference/CategoryCustomer"
import CategoryVendor from "../pages/Setup/Preference/CategoryVendor"
import Departement from "../pages/Setup/Preference/Departement"
import Divisi from "../pages/Setup/Preference/Divisi"
import PaymentTermCustomer from "../pages/Setup/Preference/PaymentTermCustomer"
import PaymentTermVendor from "../pages/Setup/Preference/PaymentTermVendor"
import PriceLevelCustomer from "../pages/Setup/Preference/PriceLevelCustomer"
import PriceLevelVendor from "../pages/Setup/Preference/PriceLevelVendor"
import CoaGroup from "../pages/Setup/Account/CoaGroup"
import CoaType from "../pages/Setup/Account/CoaType"
import ProductUnit from "../pages/Setup/Inventory/ProductUnit"
import ProductGroup from "../pages/Setup/Inventory/ProductGroup"
import ProductCategory from "../pages/Setup/Inventory/ProductCategory"
import ProductMaterial from "../pages/Setup/Inventory/ProductMaterial"
import ProductColour from "../pages/Setup/Inventory/ProductColour"
import ProductSize from "../pages/Setup/Inventory/ProductSize"
import ProductStyle from "../pages/Setup/Inventory/ProductStyle"
import ProductStatus from "../pages/Setup/Inventory/ProductStatus"
import ProductPricingLevel from "../pages/Setup/Inventory/ProductPricingLevel"
import Location from "../pages/Setup/Preference/Location"
import Project from "../pages/Setup/Preference/Project"
import Doctoral from "../pages/Setup/Preference/Doctoral"
import Magister from "../pages/Setup/Preference/Magister"
import Univercity from "../pages/Setup/Preference/Univercity"
import Academy from "../pages/Setup/Preference/Academy"
import SMA from "../pages/Setup/Preference/SMA"
import SMP from "../pages/Setup/Preference/SMP"
import SD from "../pages/Setup/Preference/SD"
import Religion from "../pages/Setup/Preference/Religion"
import TaxType from "../pages/Setup/Preference/TaxType"
import GroupPosition from "../pages/Setup/Preference/GroupPosition"
import SalaryType from "../pages/Setup/Preference/SalaryType"
import JobPosition from "../pages/Setup/Preference/JobPosition"
import EmployeeStatus from "../pages/Setup/Preference/EmployeeStatus"
import LinkAccount from "../pages/Setup/Account/LinkAccount"
import LinkAccountSales from "../pages/Setup/Account/LinkAccountSales"
import LinkAccountPurchase from "../pages/Setup/Account/LinkAccountPurchase"
import Periode from "pages/Setup/Finance/Periode"
import Comparation from "pages/Setup/Finance/Comparation"
import Module from "pages/Setup/Preference/Module"
import Menu from "pages/Setup/Preference/Menu"
import Transaction from "pages/Setup/Preference/Transaction"
import Feature from "pages/Setup/Preference/Feature"
import TypeIndustry from "pages/Setup/Preference/TypeIndustry"
import SettingCompany from "pages/Setup/Preference/SettingCompany"

// Types
import type { RouteType } from "types"

export const setupRoute: RouteType[] = [
  // ** PREFENCES **
  {
    path: "/setup/preferences/company-information",
    element: <div />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/preferences/tax-code",
    element: <TaxCode />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/preferences/location",
    element: <Location />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/preferences/project",
    element: <Project />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/preferences/bank",
    element: <Bank />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/preferences/religion",
    element: <Religion />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/preferences/tax-type",
    element: <TaxType />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/preferences/module",
    element: <Module />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/preferences/menu",
    element: <Menu />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/preferences/transaction",
    element: <Transaction />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/preferences/feature",
    element: <Feature />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/preferences/type-industry",
    element: <TypeIndustry />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/preferences/setting-company",
    element: <SettingCompany />,
    permission: "SUBSCRIBED"
  },

  // ** ACCOUNTS **
  {
    path: "/setup/accounts/coa-group",
    element: <CoaGroup />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/accounts/coa-type",
    element: <CoaType />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/accounts/link-account",
    element: <LinkAccount />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/accounts/link-account-purchase",
    element: <LinkAccountPurchase />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/accounts/link-account-sale",
    element: <LinkAccountSales />,
    permission: "SUBSCRIBED"
  },

  // ** FINANCE **
  {
    path: "/setup/finance/periode",
    element: <Periode/>,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/finance/comparation",
    element: <Comparation/>,
    permission: "SUBSCRIBED"
  },

  // ** INVENTORY **
  {
    path: "/setup/inventory/product-unit",
    element: <ProductUnit />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/inventory/product-group",
    element: <ProductGroup />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/inventory/product-category",
    element: <ProductCategory />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/inventory/product-material",
    element: <ProductMaterial />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/inventory/product-colour",
    element: <ProductColour />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/inventory/product-size",
    element: <ProductSize />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/inventory/product-style",
    element: <ProductStyle />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/inventory/product-status",
    element: <ProductStatus />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/inventory/product-pricing-level",
    element: <ProductPricingLevel />,
    permission: "SUBSCRIBED"
  },

  // ** SALES **
  {
    path: "/setup/sales/category-customer",
    element: <CategoryCustomer />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/sales/payment-term-customer",
    element: <PaymentTermCustomer />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/sales/price-level-customer",
    element: <PriceLevelCustomer />,
    permission: "SUBSCRIBED"
  },

  // Purchases
  {
    path: "/setup/purchases/category-vendor",
    element: <CategoryVendor />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/purchases/payment-term-vendor",
    element: <PaymentTermVendor />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/purchases/price-level-vendor",
    element: <PriceLevelVendor />,
    permission: "SUBSCRIBED"
  },

  // ** CARD **
  {
    path: "/setup/card/departement",
    element: <Departement />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/card/divisi",
    element: <Divisi />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/card/s3",
    element: <Doctoral />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/card/s2",
    element: <Magister />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/card/univercity",
    element: <Univercity />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/card/academy",
    element: <Academy />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/card/sma",
    element: <SMA />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/card/smp",
    element: <SMP />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/card/sd",
    element: <SD />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/card/group-position",
    element: <GroupPosition />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/card/salary-type",
    element: <SalaryType />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/card/job-position",
    element: <JobPosition />,
    permission: "SUBSCRIBED"
  },
  {
    path: "/setup/card/employee-status",
    element: <EmployeeStatus />,
    permission: "SUBSCRIBED"
  },
]