import { useContext } from "react"
import { Button, Modal } from "../../../../../components"
import { FormSection } from "./FormSection"
import * as yup from "yup"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { DataList } from "../types"
import { useApi } from "../../../../../utils"
import { Render } from "../utils"
import { menuTitle } from "./ChildItem/components/Item/components/MenuTitle"

export function CreateModal(props: {
  academy_code?: string
  academy_name?: string
  description?: string
  toggle: () => void
  refetch?: () => void
}) {

  const api = useApi()
  const { toggle } = useContext(Render)
  // const { dataCode, refetch } = useCode()

//   useEffect(() => {
//     if (dataCode?.next_code) {
//       methods.setValue("feature_code", dataCode?.next_code)
//     }
//  // eslint-disable-next-line
//   }, [dataCode?.next_code])


  type CreateData = Omit<DataList, "module_id" | "feature_id" | "module_name" | "menu_name" | "action_name"> 

  const defaultValues:CreateData= {
    menu_id: "",
    action: NaN,
    feature_code: "",
    feature_name: "",
    description: ""
  }
  const validationSchema = yup.object().shape({
    menu_id: yup.string().label("Menu").required(),
    action: yup.number().typeError('Action is a required field').required(),
    feature_code: yup.string().label("Feature code").required().max(5, 'Maximum code is 5 characters'),
    feature_name: yup.string().label("Feature name").required(),
    description: yup.string().label("Description").optional(),
  })
  const onSubmit = (value: CreateData) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/feature", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm<CreateData>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <Modal isOpen title={`CREATE ${menuTitle.title}`} size="xl" closeModal={props.toggle}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormSection isCreate />

          <Modal.Footer className="text-end">
            <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}