import { useContext } from "react"
import { Button, Modal } from "../../../../../components"
import { FormSection } from "./FormSection"
import * as yup from "yup"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { DataList } from "../types"
import { useApi } from "../../../../../utils"
import { Render } from "../utils"

export function CreateModal(props: {
  project_name?: string
  address?: string
  note?: string,
  country_id?: string,
  province_id?: string,
  regency_id?: string,
  subdistrict_id?: string,
  village_id?: string,
  toggle: () => void
  refetch?: () => void
}) {

  const api = useApi()
  const { toggle } = useContext(Render)

  type CreateData = Omit<DataList, "project_id" | "country_name" | "province_name" | "regency_name" | "subdistrict_name" | "village_name" | "countries_id"> 

  const defaultValues= {
    project_name: "",
    address: "",
    note: "",
    country_id: props.country_id ?? "",
    province_id: props.province_id ?? "",
    regency_id: props.regency_id ?? "",
    subdistrict_id: props.subdistrict_id ?? "",
    village_id: props.village_id ?? "",
  }
  const validationSchema = yup.object().shape({
    project_name: yup.string().label("Project name").required(),
    address: yup.string().label("Address").required(),
    description: yup.string().label("Description").optional(),
    country_id: yup.string().label("Country").required(),
    province_id: yup.string().label("Province").required(),
    regency_id: yup.string().label("Regency").required(),
    subdistrict_id: yup.string().label("Subdistrict").required(),
    village_id: yup.string().label("Village").required(),
  })
  const onSubmit = (value: CreateData) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/project/add", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => {
            if (err.response && err.response.status === 400) {
              return err.response.data.message || "Bad Request";
            } else {
              return "An error occurred";
            }
          }
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm<CreateData>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Modal isOpen title="CREATE PROJECT" size="xl" closeModal={props.toggle}>
          <Modal.Body>
            <FormSection />
          </Modal.Body>

          <Modal.Footer className="text-end">
            <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
          </Modal.Footer>
        </Modal>
      </form>
    </FormProvider>
  )
}