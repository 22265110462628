import { Input, Select, Textarea } from "../../../../../../components"
import { Controller, useFormContext } from "react-hook-form"
import type { DataList } from "../../types"
import { useMenu, useModule } from "./utils"
import { useEffect, useState } from "react"

export function FormSection(props: {
  isCreate?: boolean
  isDetail?: boolean
  isUpdate?: boolean
  action?: string
}) {
  const [selectedModule, setSelectedModuleState] = useState<string>("")
  const [isEditeCode, setIsEditeCode] = useState<boolean>(false)
  const { control, watch, setValue } = useFormContext<DataList>()

  const { dataModule } = useModule()
  const { dataMenu } = useMenu()

  const moduleObject = dataModule?.find(val => val?.value?.toString() === watch('module_id')?.toString())
  let moduleFind 
  if(moduleObject && moduleObject?.label) {
    if(moduleObject?.label === "ANALYTICS") {
      moduleFind = moduleObject?.label?.charAt(1)
    } else {
      moduleFind = moduleObject?.label?.charAt(0)
    }
  } else {
    moduleFind = ''
  }
  
  const menuObject = dataMenu?.find(val => val?.value === watch('menu_id'))
  let menuFind;
  if (menuObject && menuObject.label) {
    if (menuObject.label === 'REPORD' || menuObject.label === 'REPORT') {
        menuFind = menuObject.label.charAt(2);
    } else {
        menuFind = menuObject.label.charAt(0);
    }
  } else {
      menuFind = ''
  }

  const finalCode = `${moduleFind ? moduleFind : ""}${menuFind ? menuFind : ""}${moduleFind && menuFind ? "000" : ""}`

  useEffect(() => {
    if(watch('module_id')) {
      setSelectedModuleState(watch('module_id') ? watch('module_id') : "")
    } 
    // eslint-disable-next-line
  }, [watch('module_id')])

  useEffect(() => {
    if(watch('module_id') && watch('menu_id') && props?.isCreate) {
      setValue('feature_code', finalCode)
    } 
    // eslint-disable-next-line
  }, [watch('module_id') && watch('menu_id') && props?.isCreate && finalCode])

  useEffect(() => {
    if(watch('module_id') && watch('menu_id') && props?.isUpdate && isEditeCode) {
      setValue('feature_code', finalCode)
    } 
    // eslint-disable-next-line
  }, [watch('module_id') && watch('menu_id') && props?.isUpdate && isEditeCode && finalCode])

  return (
    <section>
      <Controller
        control={control}
        name="feature_code"
        render={({ field, fieldState }) => <Input {...field} label="CODE" value={field.value ?? ""} error={fieldState.error?.message} disabled={props.action === "DETAIL" || props.action === "DELETE"} />}
      />

      <Modul isDisabled={props.action === "DETAIL" || props.action === "DELETE"} setIsEditeCode={setIsEditeCode} />
      <Menu isDisabled={props.action === "DETAIL" || props.action === "DELETE"} selectedModule={selectedModule} setIsEditeCode={setIsEditeCode} />
      <Controller
        control={control}
        name="feature_name"
        render={({ field, fieldState }) => <Input {...field} label="FEATURE" value={field.value ?? ""} error={fieldState.error?.message} disabled={props.action === "DETAIL" || props.action === "DELETE"} />}
      />
      <Action isDisabled={props.action === "DETAIL" || props.action === "DELETE"} />

      <Controller
        control={control}
        name="description"
        render={({ field, fieldState }) => <Textarea {...field} label="DESCRIPTION" value={field.value ?? ""} error={fieldState.error?.message} disabled={props.action === "DETAIL" || props.action === "DELETE"} />}
      />
    </section>
  )
}

function Modul(props: { isDisabled?: boolean, setIsEditeCode: (value: boolean) => void }) {
  const { dataModule, isLoadingModule } = useModule()
  const { control, setValue } = useFormContext<DataList>()

  return (
    <Controller
      control={control}
      name="module_id"
      render={({ field, fieldState }) => (
        <Select
          label="MODUL"
          placeholder="Select Item"
          error={fieldState.error?.message}
          isDisabled={props.isDisabled}
          isLoading={isLoadingModule}
          options={dataModule}
          value={dataModule.find(item => item.value === field.value?.toString())}
          onChange={e => {
            field.onChange(e?.value)
            setValue("menu_id", "")
            props?.setIsEditeCode(true)
          }}
        />
      )}
    />
  )
}
function Menu(props: { isDisabled?: boolean, selectedModule: string, setIsEditeCode: (value: boolean) => void }) {
  const { dataMenu, isLoadingMenu, setSelectedModule, refetch } = useMenu()
  const { control } = useFormContext<DataList>()

  useEffect(() => {
    if(props?.selectedModule) {
      setSelectedModule(props?.selectedModule ? props?.selectedModule : "")
      refetch()
    } 
    // eslint-disable-next-line
  }, [props?.selectedModule])


  return (
    <Controller
      control={control}
      name="menu_id"
      render={({ field, fieldState }) => (
        <Select
          label="MENU"
          placeholder="Select Item"
          error={fieldState.error?.message}
          isDisabled={props.isDisabled || !Boolean(props?.selectedModule)}
          isLoading={isLoadingMenu}
          options={dataMenu}
          value={field.value ? dataMenu.find(item => item.value === field.value?.toString()) : ""}
          onChange={e => {
            field.onChange(e?.value)
            // props?.setMenuId(e?.value)
            props?.setIsEditeCode(true)
          }}
        />
      )}
    />
  )
}
function Action(props: { isDisabled?: boolean }) {
  const data = [
    { value: "1", label: "CREATE" },
    { value: "2", label: "READ" },
    { value: "3", label: "UPDATE" },
    { value: "4", label: "DELETE" },
    { value: "5", label: "SOFT DELETE" },
  ]

  const { control } = useFormContext<DataList>()

  return (
    <Controller
      control={control}
      name="action"
      render={({ field, fieldState }) => (
        <Select
          label="ACTION"
          placeholder="Select Item"
          error={fieldState.error?.message}
          isDisabled={props.isDisabled}
          options={data}
          value={data.find(item => item.value === field.value?.toString())}
          onChange={e => field.onChange(e?.value)}
        />
      )}
    />
  )
}