// Types
import type { MenuType } from "../types"

export const inventoryMenu = {
  menu: [
    {
      label: "REGISTER",
      link: "/inventory/register",
      permission: "IR"
    },
    {
      label: "TRANSACTIONS",
      link: "#",
      permission: "IT",
      children: [
        { label: "CREATE ITEM", link: "/inventory/transaction/add-new-item", permission: "IT01" },
        { label: "SET ITEM PRICE", link: "/inventory/transaction/set-item-price", permission: "IT02" },
        { label: "BUILT INVENTORY", link: "/inventory/transaction/built-inventori", permission: "IT03" },
        { label: "TRANSFER  INVENTORY", link: "/inventory/transaction/transfer-inventory", permission: "IT04" },
        { label: "ADJUSTMENT  INVENTORY", link: "/inventory/transaction/adjustment-inventory", permission: "IT05" },
        { label: "COUNT  INVENTORY", link: "/inventory/transaction/count-inventory", permission: "IT06" },
        { label: "TRANSACTION JOUNAL", link: "/inventory/transaction/transaction-jounal", permission: "IT07" },
        { label: "OPENING STOCK", link: "/inventory/transaction/opening-stock", permission: "IT08" },
        { label: "DASHBOARD", link: "/inventory/transaction/dashboard", permission: "IT09" },
      ]
    },
    {
      label: "REPORT",
      link: "#",
      permission: "IP",
      children: [
        { label: "ITEM LIST SUMMARY", link: "/inventory/report/list-item-summary", permission: "IP01" },
        { label: "ITEM LIST DETAIL", link: "/inventory/report/list-item-detail", permission: "IP02" },
        { label: "ITEM LIST TRANSACTION", link: "/inventory/report/list-item-transaction", permission: "IP03" },
        { label: "ANALYSE INVENTORY SUMMARY", link: "/inventory/report/analyse-inventory-summary", permission: "IP04" },
        { label: "ANALYSE INVENTORY DETAIL", link: "/inventory/report/analyse-inventory-detail", permission: "IP05" },
        { label: "BUILD COMPONENT", link: "/inventory/report/build-component", permission: "IP06" },
        { label: "INVENTORY COUNT SHEET", link: "/inventory/report/inventory-count-sheet", permission: "IP07" },
        { label: "PRICING SUMMARY", link: "/inventory/report/pricing-summary", permission: "IP08" },
        { label: "PRICING ANALYSE", link: "/inventory/report/pricing-analyse", permission: "IP09" },
        { label: "INVENTORY JOURNAL", link: "/inventory/report/inventory-journal", permission: "IP10" },
        { label: "STOCK ALERT", link: "/inventory/report/stock-alert", permission: "IP11" },
        { label: "DASHBOARD", link: "/inventory/report/dashboard", permission: "IP12" },
      ]
    },
    {
      label: "JOURNAL",
      link: "/inventory/journal",
      // permission: "IJ"
      permission: "IT" // BELUM
    },
    {
      label: "FIND",
      link: "/inventory/find",
      // permission: "IF"
      permission: "IT" // BELUM
    }
  ] as MenuType[],
}