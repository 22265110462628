// React
import { createContext } from "react"

// Types
import type { BillType } from "../types"

export const BillContext = createContext<BillType>({
  databill: {
    address: "",
    balance: 0,
    location_id: "",
    trx_code: "",
    vendor_id: "",
    vendor_name: ""
  }
})