// React
import { useContext } from "react"

// Components
import { Button, Modal } from "components"
import { FormSection } from "./FormSection"

// Form
import * as yup from "yup"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Third-Party Libraries
import toast from "react-hot-toast"
import { random } from "uniqolor"

// Types
import type { DataList } from "../types"

// Utils
import { useApi } from "utils"
import { Render } from "../utils"

export function CreateModal(props: {
  location_name?: string
  address?: string
  description?: string,
  country_id?: string,
  province_id?: string,
  regency_id?: string,
  subdistrict_id?: string,
  village_id?: string,
  onSuccess?: () => void
  toggle: () => void
  refetch?: () => void
}) {
  // Hooks
  const api = useApi()
  const { toggle } = useContext(Render)

  type CreateData = Omit<DataList, "location_id" | "country_name" | "province_name" | "regency_name" | "subdistrict_name" | "village_name"> 

  const defaultValues= {
    location_name: "",
    address: "",
    description: "",
    country_id: props.country_id ?? "",
    province_id: props.province_id ?? "",
    regency_id: props.regency_id ?? "",
    subdistrict_id: props.subdistrict_id ?? "",
    village_id: props.village_id ?? "",
    color: random().color,
  }
  const validationSchema = yup.object().shape({
    location_name: yup.string().label("Location name").required(),
    address: yup.string().label("Address").required(),
    description: yup.string().label("Description").optional(),
    country_id: yup.string().label("Country").required(),
    province_id: yup.string().label("Province").required(),
    regency_id: yup.string().label("Regency").required(),
    subdistrict_id: yup.string().label("Subdistrict").required(),
    village_id: yup.string().label("Village").required(),
    color: yup.string().label("Color").required(),
  })
  const onSubmit = (value: CreateData) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/location/add", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => {
            if (err.response && err.response.status === 400) {
              return err.response.data.message || "Bad Request";
            } else {
              return "An error occurred";
            }
          }
        }
      ).then(() => {
        props.toggle()
        toggle()
        props?.refetch && props.refetch()
        if (props.onSuccess) {
          props.onSuccess()
        }
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm<CreateData>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <Modal isOpen title="CREATE LOCATION" size="xl" closeModal={props.toggle}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormSection />

          <Modal.Footer className="text-end">
            <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}