import { Input, Select, Textarea } from "../../../../../../components"
import { Controller, useFormContext } from "react-hook-form"
import type { DataList } from "../../types"
import { menuTitle } from "../ChildItem/components/Item/components/MenuTitle"
import { useJobPosition } from "pages/Card/Transaction/CreateNewEmployee/components/FormSection/utils"

export function FormSection(props: {
  isDetail?: boolean
  isUpdate?: boolean
  action?: string
}) {

  const { control } = useFormContext<DataList>()

  return (
    <section>
      <Controller
        control={control}
        name="transaction_code"
        render={({ field, fieldState }) => <Input {...field} label="CODE" value={field.value ?? ""} error={fieldState.error?.message} disabled={props.action === "DETAIL" || props.action === "DELETE"} />}
      />

      <Controller
        control={control}
        name="transaction_name"
        render={({ field, fieldState }) => <Input {...field} label={menuTitle.title} value={field.value ?? ""} error={fieldState.error?.message} disabled={props.action === "DETAIL" || props.action === "DELETE"} />}
      />

      <JobPosition isDisabled={props.action === "DETAIL" || props.action === "DELETE"} />

      <Controller
        control={control}
        name="description"
        render={({ field, fieldState }) => <Textarea {...field} label="DESCRIPTION" value={field.value ?? ""} error={fieldState.error?.message} disabled={props.action === "DETAIL" || props.action === "DELETE"} />}
      />
    </section>
  )
}

function JobPosition(props: { isDisabled?: boolean }) {
  // Hooks
  const { dataJobPosition, isLoadingJobPosition } = useJobPosition()

  // Form
  const { control } = useFormContext<DataList>()

  return (
    <Controller
      control={control}
      name="job_position_id"
      render={({ field, fieldState }) => (
        <Select
          label="APPROVAL BY"
          placeholder="Select Item"
          error={fieldState.error?.message}
          isDisabled={props.isDisabled}
          isLoading={isLoadingJobPosition}
          options={dataJobPosition}
          value={dataJobPosition.find(item => item.value === field.value?.toString())}
          onChange={e => field.onChange(e?.value)}
        />
      )}
    />
  )
}