// React
import { useEffect, useState } from "react"

// Types
import type { SuccessFetch } from "types"

// Utils
import { useApi } from "utils"
import { default_option } from "pages/Purchase/utils"

// Types
interface Type {
  trx_code: string
  transaction_date: string
  memo: string
}
interface DataType extends Type {
  approve_status: string
  subtotal: number
}
interface FetchType extends Type {
  approve_status: number
  field_1: string
}

export function useRejectList(purchase_order_id: string): {
  data: DataType[]
  isLoading: boolean
} {
  // Vars
  const defaultData: DataType[] = []

  // Hooks
  const api = useApi()
  const [data, setData] = useState<DataType[]>(defaultData)
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    api.get("/proreject/detailreject_bypo", {
      params: { purchase_order_id }
    }).then((res: SuccessFetch<{
      payload: FetchType[]
    }>) => {
      setData(res.data.payload.map(item => {
        return {
          approve_status: default_option.find(i => i.value === item.approve_status.toString())?.label ?? "-",
          memo: item.memo,
          subtotal: parseInt(item.field_1),
          transaction_date: item.transaction_date,
          trx_code: item.trx_code
        }
      }))
    }).catch(() => {
      setData(defaultData)
    }).finally(() => {
      setLoading(false)
    })

    return () => {
      setData(defaultData)
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { data, isLoading }
}