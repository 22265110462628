/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi } from "utils"

type UseReturnItemsListBySoProps = {
  params: {
    sales_order_id: string
  }
}

type Response = {
  payload: {
    return_id: number
    invoice_id: number
    trx_code: string
    customer_id: number
    location_id: number
    location_name: string
    address: string
    memo: string
    transaction_date: string
    referensi: string
    total_return: number
    approve_status: number
    approve_date: string | null
    approve_by: string | null
    return_status_date: string | null
    company_code: string
  }[]
}

export function useReturnItemsListBySo({
  params
}: UseReturnItemsListBySoProps) {
  const api = useApi()
  const [data, setData] = useState<Response["payload"]>([])
  const [isLoading, setLoading] = useState(false)

  const fetchData = () => {
    setLoading(true)

    api
      .post("return/byso", null, { params })
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload ?? []
        setData(data)
      })
      .catch(() => setData([]))
      .finally(() => setLoading(false))
  }

  const refetch = () => fetchData()

  useEffect(() => {
    fetchData()

    return () => {
      setData([])
      setLoading(false)
    }
  }, [])

  return {
    data,
    isLoading,
    refetch,
    dropdown: data.map((item) => ({
      value: item.return_id,
      label: item.trx_code
    }))
  }
}
