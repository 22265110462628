import { Input, Textarea, Select } from "../../../../../../components"
import { Controller, useFormContext } from "react-hook-form"
import type { ListBank } from "../../types"
import { useAccountGroup } from "./utils"

export function FormSection(props: {
  isDetail?: boolean
  isUpdate?: boolean
  action?: string
}) {

  const { control } = useFormContext<ListBank>()

  return (
    <section>
      <Controller
        control={control}
        name="account_type_code"
        render={({ field, fieldState }) => 
          <Input 
            {...field} 
            label="CODE" 
            value={field.value ?? ""} 
            error={fieldState.error?.message}
            disabled={props.action === "DETAIL" || props.action === "DELETE"} 
          />}
      />

      <CoaGroup action={props.action} />

      <Controller
        control={control}
        name="account_type_name"
        render={({ field, fieldState }) => 
          <Input 
            {...field} 
            label="COA TYPE" 
            value={field.value ?? ""} 
            error={fieldState.error?.message}
            disabled={props.action === "DETAIL" || props.action === "DELETE"} 
          />}
      />

      <Controller
        control={control}
        name="description"
        render={({ field, fieldState }) => 
          <Textarea 
            {...field} 
            label="DESCRIPTION" 
            value={field.value ?? ""} 
            error={fieldState.error?.message}
            disabled={props.action === "DETAIL" || props.action === "DELETE"} 
          />}
      />
    </section>
  )
}

function CoaGroup(props: { action?: string }) {
  const { control } = useFormContext<ListBank>()
  const { data, isLoading } = useAccountGroup()
  
  return (
    <Controller
      control={control}
      name="group_coa_id"
      render={({ field, fieldState }) => (
        <Select
          label="COA GROUP"
          placeholder="Choose Coa Group"
          isLoading={isLoading}
          error={fieldState.error?.message}
          options={data}
          value={data.find(item => item.value === field.value.toString())}
          onChange={e => {
            field.onChange(e?.value);
          }}
          isDisabled={props.action === "DETAIL" || props.action === "DELETE"}
        />
      )}
    />
  )
}